.faqs{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  font: 400 14px/20px Roboto,Helvetica Neue,sans-serif;
  font-size: 16px;
  letter-spacing: normal;
}

.faq-compare{
  margin-left: -100px !important;
  display: block !important;
  overflow-x: auto !important;
  overflow-y: auto !important;
}

.ulli{
  margin-left: 25px;
  list-style-position: outside;
}

table {
  background-color: white;
  padding: 10px;
  margin-left: 0px;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #ddd;
}

th, td {
  text-align: center;
  border: 1px solid black;
  padding: 12px 16px;
}

th:first-child, td:first-child {
  text-align: left;
}

/* .styles_faq-row-wrapper__3vA1D .styles_row-body__1NvUo .styles_faq-row__2YF3c .styles_row-title__1YiiY {
  font-weight: 700;
} */

.row-title-text {
  padding-left: 30px;
  font-weight: 500;
}

.fa-check {
  color: green;
}

.fa-remove {
  color: red;
}

.faqs > h1{
  text-align: center;   
}

.faqs > p{
  padding-top: 2.5vh;
  margin: 0;
  font-size: 16px;
  font-weight: 500;
}

.faqs__padding{
  margin-top: 20px;
  box-shadow: 0px 2px 8px 0px #888888;
  padding-top: 10px;
  margin-bottom: 50px;
}

.faqs > input{
  border-radius: 5px !important;
  text-align: center !important;
  background-color: inherit !important;
  padding: 6px !important;
  font-size: 15px;
  width: 35%;
  margin: 20px;
  margin-bottom: 5px;
  border: 1px solid rgb(75, 75, 11) !important;
}

.faqs > input:focus{
  /* outline: none !important; */
  border-bottom: 1px solid rgb(31, 27, 27) !important;
}

.faqs > h1{
  font: 400 24px/32px Roboto,Helvetica Neue,sans-serif;
  font-weight: 400; 
  letter-spacing: normal;
  margin: 0 0 12px;
  padding: 25px;
  padding-bottom: 0;
  font-weight: 700 !important;
}

.faqs__padding > hr{
  height: 1px;
  border:none;
  background-color: #d9d9d9;
  color: #d9d9d9;
} 

/* Structure */
table {
  width: 100%;
}

.mat-form-field {
  font-size: 14px;
  width: 100%;
}

.topic{
  font-weight: bold;
}

.container{
  width: 100%;
}

.rowfaq{
  display: flex;
  padding-top: 25px;
  padding-bottom: 45px;
}

.col1{
  width: 30%;
}

.col2{
  width: 100%;
  text-align: center;
}

.col3{
  width: 30%;
}

.mat-form-field {
  font-size: 20px !important;
  text-align: center !important;
  font-weight: 600 !important;
}

::ng-deep .mat-focused .mat-form-field-label {
  /*change color of label*/
  color: #212121 !important;
  font-size: initial;
}

::ng-deep.mat-form-field-underline {
  /*change color of underline*/
  background-color: #212121 !important;
} 

::ng-deep.mat-form-field-ripple {
  /*change color of underline when focused*/
  background-color: #212121 !important;
  font-size: initial;
}

::ng-deep.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0,0,0,.54);
  font-size: initial;
}

.mat-header-cell {
  color: rgba(0, 0, 0, 0.95);
}

.mat-header-cell {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}

td.mat-cell:last-of-type, td.mat-footer-cell:last-of-type, th.mat-header-cell:last-of-type {
  padding-right: 0px;
  padding-left: 0px;
}

.mat-expansion-panel {
  border-radius: 0px;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #FFC107;
  color: #000;
}
  
/* Media Queries */


@media only screen and (max-width:994px){
  .faq-right{
      margin-top: -30px !important;
  }
}
  
@media only screen and (max-width:600px){
  .hidden-question{
    display: none !important;
  }
}

@media only screen and (max-width:557px) {
  .faq-row-wrapper{
    width: 100% !important;
    display: flex !important;
    padding: 0px !important;
  }

  .faqs__padding{
    width:100% !important ;
    display: flex !important;
  }
  .faq-body{
    padding-left: 0px !important;
    width: 100% !important;
  }
  .faq-row{
    margin-left: 0px !important;
  }
}

@media only screen and (max-width:500px) {
  .faq-body{
    margin-left: -10px !important;
  }
}

@media only screen and (max-width: 350px){
  .fa-check-circle{
    font-size: 14px !important;
  }

  .fa-times-circle{
    font-size: 14px !important;
  }
}