.photogallery_carousel {
  text-align: center !important;
  width: 800px;
  margin-left: 200px !important;
  /* Add this */
}
.dashboard__blacksidenav {
  width: 23rem;
  background-color: #e7e4e4;
}
.tag_inside_btn {
  height: 3rem;
  width: 7rem;
  border: none;
  outline: none;
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  background-color: #e7e4e4;
}
.tag_inside_btn:hover {
  background-color: rgb(251 249 249);
}
.profile_name {
  padding-left: 2rem;
  color: black;
  width: 100%;
  text-decoration: none;
  list-style: none;
  display: flex;
  flex-direction: left;
  align-items: center;
  height: 2.3rem;
  font-size: 1.5rem;
  margin-bottom: 0.7rem;
  margin-top: -0.4rem;
}

.profile_name:hover {
  cursor: pointer;
  background-color: rgb(251 249 249);
  text-decoration: none;
}
.tag_inside_btn > span {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-left: 3rem;
  font-size: 17px;
  letter-spacing: 1px;
}

.tag1_text {
  font-size: 17px;
  border: none;
  outline: none;
}
.my-tags {
  padding-left: 2rem;
}
.my-tags1 {
  padding-left: 2rem;
  width: 100%;
  height: 3rem;
  display: flex;
  justify-content: left;
  align-items: center;
}
.my-tags1:hover {
  cursor: pointer;
  background-color: rgb(251 249 249);
}

@media only screen and (max-width: 991px) {
  .dashboard__blacksidenav {
    width: 100%;
  }
  .left_pannel_options {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .my_tags_text {
    display: flex;
    width: 100%;
  }
  .my-tags {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .list_links {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0px;
  }
  .links_button {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .mytags1buttons {
    width: 100%;
  }
}
