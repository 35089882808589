.addNewRecord-form {
  padding: 1em;
}

.addNewRecord-form-header {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.addNewRecord-form-header > label {
  margin-bottom: 0px !important;
  font-size: 1.25rem;
  font-weight: bold;
}

.addNewRecord-form-select {
  background-color: white;
  padding: 0.5em 1em;
  border: 1px solid black;
  border-radius: 15px;
}

.addNewRecord-form-btn-wrapper {
  margin: 1em 0;
  display: flex;
  justify-content: center;
  gap: 1em;
}

.addNewRecord-form-btn {
  width: 100%;
  font-family: Roboto, Helvetica Neue, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 1px;
  padding: 7px;
  border: none;
  outline: none;
  border-radius: 15px;
  box-shadow: 0 2px 5px 0 #888;
}

.addNewRecord-popup-wrapper,
.addNewRecord-set-reminder {
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.addNewRecord-popup-wrapper-div {
  display: flex;
  /* flex-direction: column; */
  gap: 1em;
}

.addNewRecord-popup-wrapper-div > label {
  margin-bottom: 0px !important;
}

.addNewRecord-popup-wrapper-div-input {
  padding: 0.5em 1em;
  border-radius: 15px;
  border: 1px solid black;
  background-color: white;
  width: 48% !important;
}

.addNewRecord-popup-wrapper-div-textarea {
  padding: 0.5em 1em;
  border-radius: 15px;
  border: 1px solid black;
  width: 48% !important;
}
.addNewRecord-popup-wrapper-div-input.addNewRecord-small-input {
  padding: 0.5em 0.75em; /* Adjust padding as needed */
  border-radius: 15px;
  border: 1px solid black;
  background-color: white;
  width: 150px; /* Adjust width as needed */
}

.addNewRecord-popup-wrapper-div-textarea.addNewRecord-small-input {
  padding: 0.5em 0.75em; /* Adjust padding as needed */
  border-radius: 15px;
  border: 1px solid black;
  width: 50% !important;
}

.addNewRecord-uploadReport-container {
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.addNewRecord-uploadReport-container > label {
  margin-bottom: 0px !important;
  display: flex;
  gap: 0.375em;
}

/* @media only screen and (max-width: 540px) {
  .input-pet-medical,
  .input-pet-medical-dropdown {
    width: 160px !important;
  }
} */
.custom-breadcrumbs li::before {
  content: none;
}

.img-medicla-tab {
  width: 2.5rem;
  height: 2.5rem;
  margin-bottom: -8px;
}
.file-container {
  display: inline-block;
  max-width: 50%;
  border: 1px solid #ccc;
  border-radius: 15px;
  padding: 5px;
  margin-right: 10px;
  font-size: xx-small;
}

.file-icon {
  font-size: 24px;
  margin-right: 10px;
}

.file-name {
  flex-grow: 1;
}

.remove-button {
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  margin-left: 5px;
  border: none;
}

.remove-icon {
  font-size: 20px;
  color: #ff0000;
}

.remove-icon:hover {
  color: #cc0000;
}

@media (max-width: 768px) {
  .addNewRecord-popup-wrapper-div {
    flex-direction: column;
    align-items: center;
  }
  .addNewRecord-popup-wrapper-div-input {
    padding: 0.5em 1em;
    border-radius: 15px;
    border: 1px solid black;
    background-color: white;
    width: 100% !important;
  }

  .addNewRecord-popup-wrapper-div-textarea {
    padding: 0.5em 1em;
    border-radius: 15px;
    border: 1px solid black;
    width: 100% !important;
  }
}

@media (max-width: 360px) {
  .addNewRecord-popup-wrapper-div {
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
  }
}
