@import url("https://fonts.googleapis.com/css2?family=Diplomata+SC&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Federant&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Gorditas&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fruktur&family=Permanent+Marker&display=swap");
@import url("https://db.onlinewebfonts.com/c/32070eac9c9ad4cb61f359f319bb34ac?family=AbsolutDB");
@import url("https://db.onlinewebfonts.com/c/69034ec1a721c306a74b043f4138a7ed?family=BellCent+NamNum+BT");
@import url("https://db.onlinewebfonts.com/c/0ad0845267e2516d7293374db9d949c5?family=KibbyBoldFont");
@import url("https://fonts.googleapis.com/css2?family=Almarai:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Alata&display=swap");

.pet-poster-background {
  width: 60%;
  height: 100%;
  margin: auto;
  border: 1px solid rgb(202, 167, 82);
  text-align: center;
}

a {
  text-decoration: none;
  color: black;
}

a:hover {
  color: black;
}

.lost-info-text img,
.footer-font img {
  max-width: 30px;
  max-height: 30px;
  margin-left: 2px;
  margin-right: 8px;
}

.vector {
  max-width: 30px;
  max-height: 30px;
  margin-left: 2px;
  margin-right: 8px;
}

.header-font {
  font-family: "Acme", sans-serif;
  padding: 2rem;
}

.footer-font {
  font-family: "Almarai", sans-serif;
  /* font-weight: 600; */
}

#contentToConvert {
  /* display: flex;
  flex-direction: column;
  align-items: stretch; */
  position: relative;
  min-height: 100vh;
}

.canvasWrapper {
  flex: 1;
  padding-top: 20px;
}
.bottom-div {
  background: rgb(240, 248, 255);
  background: linear-gradient(
    180deg,
    rgba(240, 248, 255, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}
.poster-utility {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  gap: 1em;
  padding: 1em;
  z-index: 999;
}

.poster-utility-btns {
  width: 100%;
  border: none;
  border-radius: 5px;
  padding: 1em;
  background-color: #cca151;
  text-align: center;
  cursor: pointer;
}
.poster-utility-btns:hover {
  text-decoration: none;
}

.flex {
  display: flex;
  justify-content: space-around;
  gap: 1rem;
}

.genieposter-nav {
  padding: 1rem;
  /* position: fixed; */
  top: 0;
  display: flex;
  justify-content: space-between;
  width: 100vw;
  z-index: 3;
  background-color: white;
}

.header-text {
  font-size: 80px;
  float: center;
  margin: auto;
  /* margin-top: 30px;
    padding-bottom: 50px; */
  margin-bottom: 30px;
  line-height: 70px;
}

.petPoster-mat-card {
  width: 80%;
  margin: auto;
  margin-top: 90px;
  /* border: 1px solid #cca151; */
  box-shadow: none !important;
}

.mat-card-header {
  margin-left: 18%;
}

.bottom-inner-div {
  display: flex;
  justify-content: space-around;
  gap: 0.5rem;
}

.pet-name {
  font-size: 34px;
  margin-top: 20px;
  font-family: "Alata", sans-serif;
}

.pet-age {
  padding-top: 1rem;
  font-family: "ABeeZee", sans-serif;
}

.mat-card-image {
  /* border: 1px solid brown; */
  border-radius: 500px;
  height: 180px;
  width: 190px;
  margin: auto;
  object-fit: cover;
}
.petposter-card-image-default {
  width: 190px;
  height: 180px;
}

.img-container {
  text-align: center;
}

.lost-info-header {
  font-size: 35px;
  line-break: normal;
  line-height: normal;
  padding-top: 10px;
  padding-right: 8px;
}

.lost-info-text {
  /* color: #cca151; */
  font-size: 21px;
  /* margin-top: -20px !important; */
  border: 0.5px solid gray;
  padding: 1.2vw;
  width: 23vw;
  border-radius: 10px;
  font-family: "ABeeZee", sans-serif;
}

.lost-info-text-address {
  /* color: #cca151; */
  font-size: 21px;
  /* margin-top: 1rem; */
  margin: 2rem;
  padding: 1.5rem;
  border: 0.5px solid gray;
  border-radius: 10px;
}

.answers-to-name {
  width: 100%;
  background-color: rgb(102, 89, 32);
  color: white;
  margin: auto;
}

.answers-to-name-header {
  font-family: "KibbyBoldFont";
  font-size: 25px;
  transform: scale(1.6, 1.4);
}

.answers-to-name-text-1 {
  font-family: "AbsolutDB";
  font-size: 17px;
  /* transform: scale(1.3, 1.1); */
  /* font-weight: 600; */
  border: 0.5px solid gray;
  border-radius: 10px;
}

.pabr {
  border: 0.5px solid gray;
  border-radius: 10px;
  padding: 1rem;
  background-color: white;
}

.answers-to-name-text {
  font-family: "KibbyBoldFont";
  font-size: 25px;
  transform: scale(2.5, 2);
  font-weight: 700;
}

.bottom-help-text {
  font-size: 17px;
  transform: scale(1, 1);
}

.bottom-text {
  font-family: "BellCent NamNum BT";
  color: rgb(202, 167, 82);
  font-size: 27px;
  transform: scale(1.2, 1.6);
}

.bottom-text-1 {
  font-family: "BellCent NamNum BT";
  color: rgb(202, 167, 82);
  font-size: 27px;
  transform: scale(1.6, 1.6);
}

.reward-header {
  font-size: 25px;
  transform: scale(1.5, 1.8);
}

.yellow-color {
  color: rgb(202, 167, 82);
}

.button-styling {
  margin: 10px;
  font-size: 16px;
  border: none !important;
  outline: none !important;
}

.extra-btns {
  /* border: 1px solid blue; */
  /* display: flex; */
  margin-top: 5rem;
  /* padding: 0 20rem; */
}

.menu-bars {
  display: none;
}

.menu-overlay {
  display: none !important;
}

@media only screen and (max-width: 1120px) {
  .answers-to-name-header {
    font-size: 18px;
  }

  .lost-info-header-res {
    font-size: 28px;
  }
}

@media only screen and (max-width: 922px) {
  .answers-to-name-header {
    font-size: 15px;
  }

  .lost-info-header-res {
    font-size: 22px;
    line-break: normal;
  }

  .postar-res {
    padding: 5px 10px;
    font-size: 10px;
  }

  .button-styling {
    margin: 10px;
    font-size: 18px;
    padding: 5px;
  }

  .lost-info-text {
    width: 20vw;
    /* padding: 1rem; */
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (max-width: 768px) {
  .lost-info-header-res {
    font-size: 20px;
    line-break: normal;
  }

  .button-styling {
    width: 100% !important;
  }

  .flex {
    /* margin-top: 2rem; */
    justify-content: space-evenly;
    gap: 0.3rem;
  }

  .img-container {
    text-align: left;
    padding: 2rem;
    padding-top: 0;
  }

  .main-img {
    text-align: center;
  }
  .reported-lost-data {
    position: static;
    margin-top: 20px;
  }
  .canvasWrapper {
    flex: 1;
    padding-top: -20px;
  }
}
@media screen and (max-width:768px) and (min-width: 690px) {
  .lost-info-text {
    width: 36vw !important;
  }
}

/* .canvasWrapper {
  flex: 1;
  padding-top: 20px;
} */
@media only screen and (max-width: 690px) {
  .header-text {
    line-height: 4rem;
    padding: 2rem;
    font-size: 40px;
    margin-top: 5rem !important;
  }

  .lost-valuable {
    /* margin-top: 30px !important; */
    margin-bottom: 10px !important;
  }

  .answers-to-name-header {
    font-size: 12px !important;
  }

  .button-styling {
    margin: 10px;
    font-size: 18px;
    padding: 5px;
  }

  .bottom-inner-div {
    flex-direction: column;
    justify-content: center;
  }

  p {
    margin-bottom: 0;
  }

  .bottom-div p {
    /* padding-bottom: 1rem; */
    font-size: 16px !important;
    font-weight: 100 !important;
  }

  .lost-info-text img,
  .footer-font img,
  .vector {
    max-width: 20px;
    max-height: 20px;
    margin-left: 2px;
    margin-right: 8px;
  }

  .petPoster-mat-card {
    border: none;
    width: 100%;
    margin: 0;
    margin-bottom: 13rem !important;
  }

  .img-container {
    text-align: left;
    padding: 2rem;
    padding-top: 0;
    /* padding-top: 0; */
    /* margin-top: 4rem; */
    background-color: white;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
  }

  .colorw {
    background-color: white !important;
  }

  .p5 {
    padding-bottom: 6rem;
  }

  .main-img {
    text-align: center;
    position: absolute;
    top: -120px;
    left: 48%;
    margin-left: -10%;
    width: 60px !important;
    height: 60px;
  }

  .card-bg {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding-top: 30px; */
    /* background-color: aliceblue; */
  }

  .bottom-div {
    position: fixed;
    left: 0;
    right: 0;
    background-color: #ffffff;
    /* padding: 20px; */
    /* padding-bottom: 20px; */
    text-align: center;
    /* z-index: 1; */
  }

  /* .reported-lost-data { */
  /* position: relative; */
  /* z-index: 2; */
  /* } */
  .lost-info-text {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    width: 43vw;
    /* height: 10vh; */
  }

  .mat-card-image {
    height: 160px !important;
    width: 160px !important;
    border: 5px solid white;
  }
  .petposter-card-image-default {
    width: 160px;
    height: 160px;
  }

  .flex {
    margin-top: 0.5rem;
    margin-right: 0.5rem;
    gap: 0;
  }

  .header-font {
    padding-bottom: 0;
  }

  .genieposter-nav {
    /* background-color: rgb(243, 243, 243); */
    background-color: transparent;
  }

  .row {
    background-color: rgb(243, 243, 243);
  }

  .footer-font {
    text-align: left;
  }

  .flex-between {
    display: flex;
    justify-content: space-between;
  }

  .emergency-btn {
    display: none;
  }

  .extra-btns {
    display: none;
  }

  .menu-bars {
    display: block;
    margin-top: 1rem;
    font-size: 1.5rem;
  }

  .menu-overlay-show {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: absolute;
    background-color: white;
    transition: all 0.5s ease-in-out 0s;
    z-index: 999;
    gap: 1em;
    padding: 1rem 0rem 1rem 0rem;
    list-style: none;
  }

  .menu-overlay-links {
    background-color: #dab65a;
    width: 95%;
    /* border-bottom: 0.5px solid black; */
    padding: 0.5rem 0rem 0.7rem 8rem;
    border-radius: 10px;
  }
  .menu-overlay-links-go {
    background-color: #dab65a;
    width: 95%;
    /* border-bottom: 0.5px solid black; */
    padding: 0.5rem 0rem 0.7rem 8rem;
    border-radius: 10px;
  }
  .menu-overlay-links-down {
    background-color: #dab65a;
    width: 95%;
    /* border-bottom: 0.5px solid black; */
    padding: 0.5rem 0rem 0.7rem 9rem;
    border-radius: 10px;
  }
  .menu-overlay-links-edit {
    background-color: #dab65a;
    width: 95%;
    /* border-bottom: 0.5px solid black; */
    padding: 0.5rem 0rem 0.7rem 10rem;
    border-radius: 10px;
  }
  .details-right-side {
    padding-right: 0.4rem;
  }
}

@media only screen and (max-width: 500px) {
  .answers-to-name-header {
    font-size: 10px !important;
  }

  /* .bottom-div {
    position: fixed;
    bottom: 0rem;
    padding: 5%;
    padding-bottom: 2vh;
  } */

  .main-img {
    text-align: center;
    position: absolute;
    top: -120px;
    left: 45%;
    margin-left: -10%;
    width: 60px !important;
    height: 60px;
  }
}

@media only screen and (max-width: 450px) {
  .answers-to-name-header {
    font-size: 8px !important;
  }

  /* .bottom-div {
    position: fixed;
    bottom: 0rem;
    padding: 5%;
    padding-bottom: 2vh;
  } */

  .lost-info-text {
    width: 48vw;
  }

  /* .for-margin {
    margin: 1rem 7.8rem;
  } */

  .main-img {
    text-align: center;
    position: absolute;
    top: -120px;
    left: 42%;
    margin-left: -10%;
    width: 60px !important;
    height: 60px;
  }
}

@media only screen and (max-width: 400px) {
  .petPoster-mat-card {
    width: 100%;
  }

  .header-text {
    line-height: 4.5rem;
  }

  .lost-info-header {
    font-size: 20px;
  }

  .answers-to-name {
    padding: 40px !important;
  }

  .answers-to-name-header {
    font-size: 14px !important;
  }

  .answers-to-name-text {
    font-size: 15px;
  }

  .bottom-text-1 {
    font-size: 18px;
  }

  .reward-header {
    padding: 10px 0px 0px 0px;
  }

  .bottom-text {
    padding: 0px 0px 5px 0px;
    font-size: 20px !important;
  }

  .reward-header {
    font-size: 20px !important;
  }

  /* .bottom-div {
    padding: 5%;
    padding-bottom: 1rem;
  } */
}

@media only screen and (max-width: 298px) {
  .lost-info-header-respon {
    font-size: 18px;
  }

  .button-styling {
    margin: 12px;
    font-size: 15px;
    padding: 10px;
  }

  .petPoster-mat-card {
    width: 100%;
  }

  /* .bottom-div {
    bottom: 0;
  } */
}

.lost-info-span {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100% !important;
}

.lost-info-span > p {
  height: 5rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 992px) {
  .lost-info-span > p {
    width: 45%;
  }
}

@media screen and (max-width: 524px) {
  .lost-info-span {
    flex-direction: column !important;
  }
  .lost-info-span > p {
    width: 90%;
  }
  .lost-info-text-address {
    width: 90%;
  }
}
