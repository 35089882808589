.footer {
  width: 100%;
  height: 20px;
  background-color: #272730;
  text-align: center;
  color: white;
  padding-top: 1px;
  padding-left: 15px;
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  z-index: 999;
}
.footer .left-content {
  flex: 1;
}

.footer .right-content {
  flex: 1;
  text-align: center;
}
.footer .left-content {
  flex: 1;
}

.footer .right-content {
  flex: 1;
  text-align: center;
}
.footer-div {
  width: 100%;
  height: max-content !important;
  background-image: linear-gradient(to right, #ccad5e, #fdcb4d);
  font-family: "Times New Roman", Times, serif;
  font-weight: 500;
  padding-left: 30px;
  padding-right: 30px;
}

.online-store {
  text-align: center;
  margin-right: 10px;
}

.online-store img {
  height: 60px !important;
  border-radius: 10px;
  box-shadow: 3px 3px 6px grey;
}

.footer-div-3 {
  height: 200px;
  text-align: center;
}

footerdiv3 {
  margin-top: 40px !important;
}

.footer-div-3 div {
  text-align: center !important;
  margin-top: 10px !important;
}

.footer-div4-1 {
  padding: 10px 5px;
  display: flex;
  flex-direction: row;
}

.footer-div4-1 button {
  width: 150px;
  height: 40px;
  background-color: transparent;
  border: 1px solid black;
  margin-right: 5px;
}
.footer-div4-2 {
  padding: 10px 5px;
  display: flex;
  flex-direction: "row";
  margin-top: -5px;
}
.footer-div4-2 button {
  width: 210px;
  height: 40px;
  background-color: transparent;
  border: 1px solid black;
  margin-right: 5px;
}

.link-btn {
  width: 180px;
  height: 40px;
  background-color: transparent;
  border: 1px solid black;
  margin-right: 5px;
  margin-left: -5px;
}

.link-btn1 {
  width: 210px;
  height: 40px;
  background-color: transparent;
  border: 1px solid black;
  margin-right: 5px;
  margin-left: -5px;
}

/* Media Queries */
/* @media screen and (max-height: 600px) {
  .footer {
    position: static;
  }
} */
/* @media screen and (max-height: 600px) {
  .footer {
    position: static;
  }
} */

@media screen and (max-width: 768px) {
  .footer {
    height: 65px;
    height: 65px;
  }
  .footer-div-3 div {
    text-align: left !important;
  }

  .link-btn {
    width: 110px !important;
    height: 40px !important;
    background-color: transparent;
    border: 1px solid black;
    margin-right: 5px;
    margin-left: -5px;
  }

  .link-btn1 {
    width: 150px !important;
    height: 40px !important;
    background-color: transparent;
    border: 1px solid black;
    margin-right: 5px;
    margin-left: -5px;
  }
}

@media screen and (max-width: 400px) {
  .online-store img {
    height: 60px !important;
    width: 90px !important;
  }
  .footer {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .footer .left-content,
  .footer .right-content {
    font-size: 10px;
  }
}
