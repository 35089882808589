@import url(https://fonts.googleapis.com/css2?family=Prompt&family=Ubuntu:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Prompt&family=Ubuntu:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@1,300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Diplomata+SC&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Federant&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Gorditas&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fruktur&family=Permanent+Marker&display=swap);
@import url(https://db.onlinewebfonts.com/c/32070eac9c9ad4cb61f359f319bb34ac?family=AbsolutDB);
@import url(https://db.onlinewebfonts.com/c/69034ec1a721c306a74b043f4138a7ed?family=BellCent+NamNum+BT);
@import url(https://db.onlinewebfonts.com/c/0ad0845267e2516d7293374db9d949c5?family=KibbyBoldFont);
@import url(https://fonts.googleapis.com/css2?family=Almarai:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Alata&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Diplomata+SC&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Federant&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Gorditas&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fruktur&family=Permanent+Marker&display=swap);
@import url(https://db.onlinewebfonts.com/c/32070eac9c9ad4cb61f359f319bb34ac?family=AbsolutDB);
@import url(https://db.onlinewebfonts.com/c/69034ec1a721c306a74b043f4138a7ed?family=BellCent+NamNum+BT);
@import url(https://db.onlinewebfonts.com/c/0ad0845267e2516d7293374db9d949c5?family=KibbyBoldFont);
@import url(https://fonts.googleapis.com/css2?family=Almarai:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Alata&display=swap);
*{
    font-style: normal;
    font-feature-settings: normal;
    font-variant-ligatures: normal;
    font-variant-caps: normal;
    font-variant-numeric: normal;
    font-variant-east-asian: normal;
    font-stretch: normal;
    font-family: Roboto, "Helvetica Neue", sans-serif;
  }
* {
  font-style: normal;
  font-feature-settings: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-stretch: normal;
  line-height: 20px;
  font-family: "prompt", sans-serif;
}

@media only screen and (max-width: 600px) {
  select {
    background-color: white !important;
  }
  input {
    background-color: white !important;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

.PageWrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  /* min-height: 135vh; */
}

.AppWrapper {
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

.FooterWrapper {
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  z-index: 1;
}

/* .header-section {
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  background-color: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  /* width: 100%; */
/* z-index: 1000; */
/* }  */
.header-change-password-popup {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  grid-gap: 2em;
  gap: 2em;
  margin: 1em;
}

.header-change-password-popup > label {
  margin: 0px !important;
  font-size: 25px;
  font-weight: bold;
}

.header-change-password-popup-input-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  grid-gap: 1em;
  gap: 1em;
}

.header-change-password-popup-checkbox-input-container {
  display: -webkit-flex;
  display: flex;
}

.header-change-password-popup-input {
  position: relative;
}

.header-change-password-popup-checkbox {
  display: none;
}

.header-change-password-popup-input {
  margin: 0px !important;
  border: 1px solid black;
  border-radius: 5px;
  padding: 1em 2em;
  background-color: white !important;
}

.header-change-password-popup-checkbox-container > label {
  position: absolute;
  padding: 1em;
  right: 1.125em;
}

.header-change-password-popup-btns {
  display: -webkit-flex;
  display: flex;
  grid-gap: 1em;
  gap: 1em;
}

.header-change-password-popup-btns > button {
  border: none;
  border-radius: 5px;
  padding: 1em 1.5em;
}

.header-section {
  position: fixed;
  height: 149px;
  background-color: #ffffff;
  left: 0;
  width: 100%;
  z-index: 1000;
  border-bottom: 1px solid rgb(167 167 167);
}
/* .header-sticky {
  position: fixed;
  top: 0;
  width: 100%;
  /* height: 180px; */
  /* z-index: 1000;
  transition: top 0.3s; */
/* }  */

.announcement-bar-1-hidden {
  display: none;
}
/* .announcement-bar-1 {
  background: rgb(var(--color-announcement-bar-background-1));
  color: #fff !important;
  text-align: center !important;
  border-bottom: 45px solid #000 !important;
  margin-top: -3.8rem !important;
  width: 105% !important;
  box-sizing: border-box !important;
} */
.announcement-bar-1 {
  position: -webkit-sticky;
  position: sticky; /* Keeps it visible at the top */
  top: 0;
  z-index: 1000; /* Ensures it stays above other elements */
  background: #252525f5;
  color: #fff;
  width: 100%; 
}

.announcement-bar__wrapper {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.announcement-bar__message {
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-align-items: center !important;
          align-items: center !important;
  -webkit-justify-content: center !important;
          justify-content: center !important;
  -webkit-flex-wrap: wrap !important;
          flex-wrap: wrap !important;
  grid-gap: 0.8rem !important;
  gap: 0.8rem !important;
  text-align: center !important;
  padding: 0.6rem 0.6rem !important;
  min-height: 1rem !important;
}
.announcement-bar__wrapper::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  height: 1px;
  background: #000;
}
.announcement-bar__border {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  height: 1px;
  background: #000;
}
.announcement-bar__text {
  font-weight: 400 !important;
  line-height: 145% !important;
}
.announcement-bar__text p {
  margin: 0 !important;
}
.announcement-bar__text p strong {
  /* margin: 0 !important; */
  font-size: 0.8rem !important;
}
a {
  position: relative;
  text-decoration: none;
  color: #000;
}
/* a :hover {
  color: #000;
} */
.header-nav-ul-li a::before {
  content: "";
  position: absolute;
  width: 60%;
  height: 2px;
  border-radius: 4px;
  background-color: #ffc200;
  bottom: 16px;
  /* left: 0; */
  -webkit-transform-origin: right;
          transform-origin: right;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

.header-nav-ul-li a:hover::before {
  -webkit-transform-origin: left;
          transform-origin: left;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  right: 15px;
  color: black;
}

.header-nav-ul-li a:hover {
  color: black;
}
.profile-header-nav-ul-li a::before {
  content: "";
  position: absolute;
  width: 80%;
  height: 2px;
  border-radius: 4px;
  background-color: #ffc200;
  bottom: 3px;
  /* left: 0; */
  -webkit-transform-origin: right;
          transform-origin: right;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

.profile-header-nav-ul-li a:hover::before {
  -webkit-transform-origin: left;
          transform-origin: left;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  right: 10%;
  color: black;
}

.profile-header-nav-ul-li a:hover {
  color: black;
}
.announcement-bar__text a {
  position: relative !important;
  color: inherit !important;
  text-underline-offset: 0.4rem !important;
  -webkit-text-decoration-color: inherit !important;
          text-decoration-color: inherit !important;
  z-index: 2 !;
}
.header-activateTag-popup {
  font-family: "prompt";
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  padding: 16px 24px;
  margin-bottom: 0;
  text-align: center;
}

.header-input__field__dialogs {
  padding: 0.375em 0.75em !important;
  border: 1px solid black;
  border-radius: 15px;
}

.header-verifyOtp-button {
  font-family: "prompt";
  font-size: 14px;
  font-weight: 500;
  background-color: #ffc107;
  letter-spacing: 1px;
  padding: 8px 16px;
  border: none;
  outline: none;
  border-radius: 15px;
  color: white;
  box-shadow: 0px 2px 5px 0px #888888;
}

.header-verifyOtp-button:hover {
  background-color: #ebb105;
}

.header-activateTag-popup {
  font-family: "prompt";
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  padding: 16px 24px;
  margin-bottom: 0;
  text-align: center;
}
.logo-container {
  text-align: center;
  margin: 1rem;
}

.logo {
  max-width: 100%;
  margin-bottom: 20px;
}

.logo-image {
  max-width: 70%;
  height: auto;
  max-height: 60px;
}

.navbar-toggle-btn {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  margin-right: auto;
  margin-top: -45px;
}

.header-nav {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
}
.header-nav-hide {
  /* background: whitesmoke; */
  /* position: absolute; */
  width: 100%;
  /* top: 100%;
  right: -100%; */
  transition: 350ms;
}

/* .header-nav-hide {
  background: #f5f5f5;
  position: absolute;
  top: 100%;
  transition: 0.35s;
} */
.header-nav-ul-li {
  margin-right: 10px;
}
.navbar__options span {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  margin: -1rem;
}

.navbar__options {
  color: black;
  font-family: "prompt";
  font-size: 16px !important;
  text-decoration: none !important;
  transition: all 0.2s ease-in-out;
  padding: 1.7rem 1rem !important;
}
.navbar__options:hover {
  color: black;
  text-decoration: none;
  cursor: default;
  outline: none;
}
.got-genie1 {
  height: 55%;
  padding: 10px;
  margin-right: 1rem;
  font-family: "prompt";
  border-radius: 10px;
  width: 165px;
  font-size: 15px;
  font-weight: bold;
  border: 1px solid #a78107;
  border-radius: 10px;
  margin-right: 1rem;
}
.header-nav-hide .close-button {
  display: none;
}
/* .got-genie-top {
  color: #00000000;
} */

.got-genie-div-1 {
  display: none;
  margin-left: auto;
  -webkit-align-items: center;
          align-items: center;
  overflow: hidden;
  float: left !important;
  outline: none;
}

.got-genie-form {
  margin: 5px;
  padding: 5px;
  margin-right: 4px;
  margin-left: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  background-color: #24292d;
  border: 1px solid #504d4d;
  border-radius: 10px;
}

.got-genie-form > input {
  width: 70%;
  height: 30px;
  -webkit-align-self: center;
          align-self: center;
  text-align: center;
  border-radius: 5px;
  margin-top: 10px;
}
.genie-form-buttons {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  border-radius: 5px;
}

.genie-form-buttons > button {
  background-color: white;
  padding: 5px 13px;
  margin: 10px 8px;
  margin-top: 12px;
  border-radius: 5px;
}
.genie-form-buttons > button:hover {
  background-color: aliceblue;
  cursor: pointer;
}

.genie-form-buttons > button:hover:disabled {
  cursor: inherit;
}

.genie-form-buttons > button:disabled {
  background-color: #d3d3d3;
}

/* .nav-link {
  text-decoration: none;
  color: #000000;
  font-size: 18px;
} */

@media screen and (max-width: 2000px) {
  .navbar-toggle-btn {
    display: none;
  }
  .header-nav-ul {
    list-style: none;
    /* padding: 0; */
    /* margin: 0; */
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    /* align-items: center; */
  }
  /* .header-nav-hide {
    width: fit-content;
    display: flex;
    top: 0;
    right: 0;
  } */

  /* .header-nav-ul {
    display: flex;
    align-items: center;
    height: 3.3em;
    max-width: 60em;
  } */
}

@media (max-width: 768px) {
  /* .header-nav-ul.show {
    display: flex; /* Show the options when the show class is applied */
  /* } */
  .header-nav-ul {
    list-style: none;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; /* Change direction to column for smaller screens */
    -webkit-align-items: center;
            align-items: center; /* Center items vertically */
  }

  .header-nav-ul-li a::before {
    display: none; /* Hide the pseudo-element on smaller screens */
  }

  .header-nav-ul-li a:hover::before {
    -webkit-transform: scaleX(
      0
    );
            transform: scaleX(
      0
    ); /* Ensure the transform is reset for smaller screens */
  }
  .navbar-toggle-btn {
    display: block; /* Show the button */
  }
  .header-section {
    height: 65px;
    position: fixed !important;
    background-color: #ffffff;
    /* top: 40px; Ensure the header stays at the top */
    z-index: 1000;
  }
  .announcement-bar-1 {
    width: 100%;
  }

  .logo-container {
    text-align: left;
    margin: 0.8rem 0rem 0.5rem 3rem;
    width: 9rem;
  }
  .announcement-bar__message {
    padding: 0.8rem 1.6rem !important;
  }
  /* .header-nav {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: hsl(0, 13%, 89%);
    padding-top: 50px;
    transition: transform 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
  } */

  .header-nav .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    background-color: transparent;
    border: none;
    padding: 0;
    font-size: 24px;
    color: #333;
    outline: none;
    transition: color 0.3s ease;
  }

  .header-nav .close-button:hover {
    color: #ff0000;
  }

  .header-nav {
    width: 85%;
    height: 100vh;
    position: fixed;
    top: 13%;
    left: 0;
    background-color: #ffffff;
    padding-top: 50px;
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    z-index: 2;
  }

  .header-nav ul {
    list-style: none;
    padding: 0;
    margin: -3rem;
  }

  .header-nav li {
    position: relative;
  }

  .header-nav li:not(:last-child):after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #333; /* Color of the line */
    bottom: 0;
    left: 0;
  }

  .navbar__options {
    padding: 1rem 3rem !important;
  }
  .header-nav li a {
    padding: 10px; /* Adjust padding as needed */
    text-decoration: none;
    color: #333;
  }

  .header-nav-hide {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  /* .header-nav-ul-li a::before {
    display: none;
  } */
}
/* @media (max-width: 767px) {
  .logo-container,
  .navbar-toggle-btn {
    display: block;
  }
} */

@media (max-width: 360px) {
  .header-nav {
    top: 18%;
  }
}

.footer {
  width: 100%;
  height: 20px;
  background-color: #272730;
  text-align: center;
  color: white;
  padding-top: 1px;
  padding-left: 15px;
  margin-top: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  z-index: 999;
}
.footer .left-content {
  -webkit-flex: 1 1;
          flex: 1 1;
}

.footer .right-content {
  -webkit-flex: 1 1;
          flex: 1 1;
  text-align: center;
}
.footer .left-content {
  -webkit-flex: 1 1;
          flex: 1 1;
}

.footer .right-content {
  -webkit-flex: 1 1;
          flex: 1 1;
  text-align: center;
}
.footer-div {
  width: 100%;
  height: -webkit-max-content !important;
  height: max-content !important;
  background-image: linear-gradient(to right, #ccad5e, #fdcb4d);
  font-family: "Times New Roman", Times, serif;
  font-weight: 500;
  padding-left: 30px;
  padding-right: 30px;
}

.online-store {
  text-align: center;
  margin-right: 10px;
}

.online-store img {
  height: 60px !important;
  border-radius: 10px;
  box-shadow: 3px 3px 6px grey;
}

.footer-div-3 {
  height: 200px;
  text-align: center;
}

footerdiv3 {
  margin-top: 40px !important;
}

.footer-div-3 div {
  text-align: center !important;
  margin-top: 10px !important;
}

.footer-div4-1 {
  padding: 10px 5px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.footer-div4-1 button {
  width: 150px;
  height: 40px;
  background-color: transparent;
  border: 1px solid black;
  margin-right: 5px;
}
.footer-div4-2 {
  padding: 10px 5px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: "row";
          flex-direction: "row";
  margin-top: -5px;
}
.footer-div4-2 button {
  width: 210px;
  height: 40px;
  background-color: transparent;
  border: 1px solid black;
  margin-right: 5px;
}

.link-btn {
  width: 180px;
  height: 40px;
  background-color: transparent;
  border: 1px solid black;
  margin-right: 5px;
  margin-left: -5px;
}

.link-btn1 {
  width: 210px;
  height: 40px;
  background-color: transparent;
  border: 1px solid black;
  margin-right: 5px;
  margin-left: -5px;
}

/* Media Queries */
/* @media screen and (max-height: 600px) {
  .footer {
    position: static;
  }
} */
/* @media screen and (max-height: 600px) {
  .footer {
    position: static;
  }
} */

@media screen and (max-width: 768px) {
  .footer {
    height: 65px;
    height: 65px;
  }
  .footer-div-3 div {
    text-align: left !important;
  }

  .link-btn {
    width: 110px !important;
    height: 40px !important;
    background-color: transparent;
    border: 1px solid black;
    margin-right: 5px;
    margin-left: -5px;
  }

  .link-btn1 {
    width: 150px !important;
    height: 40px !important;
    background-color: transparent;
    border: 1px solid black;
    margin-right: 5px;
    margin-left: -5px;
  }
}

@media screen and (max-width: 400px) {
  .online-store img {
    height: 60px !important;
    width: 90px !important;
  }
  .footer {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .footer .left-content,
  .footer .right-content {
    font-size: 10px;
  }
}

.faqs{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  text-align: left;
  font: 400 14px/20px Roboto,Helvetica Neue,sans-serif;
  font-size: 16px;
  letter-spacing: normal;
}

.faq-compare{
  margin-left: -100px !important;
  display: block !important;
  overflow-x: auto !important;
  overflow-y: auto !important;
}

.ulli{
  margin-left: 25px;
  list-style-position: outside;
}

table {
  background-color: white;
  padding: 10px;
  margin-left: 0px;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #ddd;
}

th, td {
  text-align: center;
  border: 1px solid black;
  padding: 12px 16px;
}

th:first-child, td:first-child {
  text-align: left;
}

/* .styles_faq-row-wrapper__3vA1D .styles_row-body__1NvUo .styles_faq-row__2YF3c .styles_row-title__1YiiY {
  font-weight: 700;
} */

.row-title-text {
  padding-left: 30px;
  font-weight: 500;
}

.fa-check {
  color: green;
}

.fa-remove {
  color: red;
}

.faqs > h1{
  text-align: center;   
}

.faqs > p{
  padding-top: 2.5vh;
  margin: 0;
  font-size: 16px;
  font-weight: 500;
}

.faqs__padding{
  margin-top: 20px;
  box-shadow: 0px 2px 8px 0px #888888;
  padding-top: 10px;
  margin-bottom: 50px;
}

.faqs > input{
  border-radius: 5px !important;
  text-align: center !important;
  background-color: inherit !important;
  padding: 6px !important;
  font-size: 15px;
  width: 35%;
  margin: 20px;
  margin-bottom: 5px;
  border: 1px solid rgb(75, 75, 11) !important;
}

.faqs > input:focus{
  /* outline: none !important; */
  border-bottom: 1px solid rgb(31, 27, 27) !important;
}

.faqs > h1{
  font: 400 24px/32px Roboto,Helvetica Neue,sans-serif;
  font-weight: 400; 
  letter-spacing: normal;
  margin: 0 0 12px;
  padding: 25px;
  padding-bottom: 0;
  font-weight: 700 !important;
}

.faqs__padding > hr{
  height: 1px;
  border:none;
  background-color: #d9d9d9;
  color: #d9d9d9;
} 

/* Structure */
table {
  width: 100%;
}

.mat-form-field {
  font-size: 14px;
  width: 100%;
}

.topic{
  font-weight: bold;
}

.container{
  width: 100%;
}

.rowfaq{
  display: -webkit-flex;
  display: flex;
  padding-top: 25px;
  padding-bottom: 45px;
}

.col1{
  width: 30%;
}

.col2{
  width: 100%;
  text-align: center;
}

.col3{
  width: 30%;
}

.mat-form-field {
  font-size: 20px !important;
  text-align: center !important;
  font-weight: 600 !important;
}

::ng-deep .mat-focused .mat-form-field-label {
  /*change color of label*/
  color: #212121 !important;
  font-size: medium;
  font-size: initial;
}

::ng-deep.mat-form-field-underline {
  /*change color of underline*/
  background-color: #212121 !important;
} 

::ng-deep.mat-form-field-ripple {
  /*change color of underline when focused*/
  background-color: #212121 !important;
  font-size: medium;
  font-size: initial;
}

::ng-deep.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0,0,0,.54);
  font-size: medium;
  font-size: initial;
}

.mat-header-cell {
  color: rgba(0, 0, 0, 0.95);
}

.mat-header-cell {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}

td.mat-cell:last-of-type, td.mat-footer-cell:last-of-type, th.mat-header-cell:last-of-type {
  padding-right: 0px;
  padding-left: 0px;
}

.mat-expansion-panel {
  border-radius: 0px;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #FFC107;
  color: #000;
}
  
/* Media Queries */


@media only screen and (max-width:994px){
  .faq-right{
      margin-top: -30px !important;
  }
}
  
@media only screen and (max-width:600px){
  .hidden-question{
    display: none !important;
  }
}

@media only screen and (max-width:557px) {
  .faq-row-wrapper{
    width: 100% !important;
    display: -webkit-flex !important;
    display: flex !important;
    padding: 0px !important;
  }

  .faqs__padding{
    width:100% !important ;
    display: -webkit-flex !important;
    display: flex !important;
  }
  .faq-body{
    padding-left: 0px !important;
    width: 100% !important;
  }
  .faq-row{
    margin-left: 0px !important;
  }
}

@media only screen and (max-width:500px) {
  .faq-body{
    margin-left: -10px !important;
  }
}

@media only screen and (max-width: 350px){
  .fa-check-circle{
    font-size: 14px !important;
  }

  .fa-times-circle{
    font-size: 14px !important;
  }
}
.legalterms{
    padding: 50px 250px;
}
.legalterms > h1{
    font: 400 14px/20px Roboto,Helvetica Neue,sans-serif;
    margin-top: 50px;
    font-size: 30px;
    letter-spacing: 2px;
    font-weight: 500;
    color: #ffc107 ;
    text-align: center;
    margin-bottom: 2rem !important;
}
.legalterms > p{
    font: 400 14px/20px Roboto,Helvetica Neue,sans-serif;
    font-size: 16px !important;
    color: gray;
    letter-spacing: 1px;
    line-height: 27px;
    text-align: justify;
    margin-top: 1rem !important;    
    margin-right: -15px;
    margin-left: -15px;

}

/* Media Queries */

@media only screen and (max-width:1024px) and (min-width:768px) {
    .legalterms p{
        width:  100% !important;
    }
    .legalterms{
        padding: 25px 40px !important;
    }
    .legalterms h1{
        font-size: 28px !important;
        line-height: 35px !important;
    }
}

@media only screen and (max-width:768px){

    .legalterms p{
        width:  100% !important;
        margin-left: 5px !important;
    }
    .legalterms{
        padding-left: 40px !important;
        padding-right: 40px !important;

        padding-top: 25px !important;
        padding-bottom: 25px !important;

    }
    .legalterms h1{
        font-size: 28px !important;
        line-height: 35px !important;
    }
    
}
.pet-activation-container {
  padding: 1em;
}

.petactivation-label-container {
  padding: 1em;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.petactivation-label-petdata {
  font-size: 1.5rem;
  margin-top: 1em;
  margin-bottom: 1em;
}

.pet-activation-container > p {
  text-align: center;
}

.petactivation-input-tag {
  margin-top: 0 !important;
  padding: 1rem 2rem !important;
  border: 1px solid black;
  border-radius: 5px;
}

.petactivation-drop-down {
  background-color: white;
  border: 1px solid black;
  padding: 1rem 2rem !important;
  border-radius: 5px !important;
}

.petactivation-gender-div {
  padding: 1em;
}

.petactivation-btn-grp {
  display: -webkit-flex;
  display: flex;
  grid-gap: 1em;
  gap: 1em;
}

.petActivation-main-wrapper {
  width: 100%;
  position: absolute;
  top: 5.3rem;
}

.petActivation-left-wrapper {
  position: relative;
}

.petActivation-sidebar-container {
  width: 100%;
  height: 100vh;
  z-index: 800;
  position: absolute;
  background-color: #e6e5e3;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  left: 0;
  transition: 350ms;
}

.petActivation-sidebar-container-hide {
  width: 100%;
  height: 100vh;
  z-index: 800;
  position: absolute;
  background-color: whitesmoke;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  left: -100%;
  transition: 350ms;
}

.petActivation-sidebar-toggle-btn-wrapper {
  padding: 1rem;
  width: 100%;
}

.petActivation-sidebar-toggle-btn {
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
}

.petActivation-sidebar-menu-link {
  background-color: transparent;
  border: none;
  padding: 1rem;
  text-align: left;
  font-size: 1.21875rem;
}

.petActivation-sidebar-menu-ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.petActivation-sidebar-menu-ul-hide {
  display: none;
}

.petActivation-sidebar-menu-ul-links {
  border: none;
  background-color: transparent;
  padding: 1rem 1rem 1rem 2rem;
}

.MuiFormControlLabel-label {
  font-size: 14px !important;
  color: #b4b4b4;
}

.body-login {
  width: 80%;
  border-radius: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
  margin-top: 15px;
}
.petparentlinks {
  padding-left: 2rem;
  /* margin-top: 2rem; */
}
.image-upload {
  width: 120px;
  height: 120px;
  margin: auto !important;
}

#choose-profile-imageActi {
  display: none;
}
.add_img {
  font-size: 18px;
  font-weight: 800;
  letter-spacing: 1px;
  color: black;
}
.fa-plus {
  color: gray;
  font-size: 14px;
  cursor: pointer;
}

#profile-image-uploadActi {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
/* .dashboard__blacksidenav {
  width: 19rem;
  background-color:  #e7e4e4;
}
.tag_inside_btn {
  height: 2rem;
  width: 7rem;
  border: none;
  outline: none;
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
}
.profile_name {
  padding-left: 2rem;
  color: black;
  width: 100%;
  text-decoration: none;
  list-style: none;
  display: flex;
  flex-direction: left;
  align-items: center;
  height: 2.3rem;
  margin-top: 2rem;
  font-size: 1.5rem;
  margin-bottom: 0.7rem;
}

.profile_name:hover {
  cursor: pointer;
  background-color:  rgb(251 249 249) ;
  text-decoration: none;
}
.tag_inside_btn > span {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-left: 3rem;
}
.tag1_text {
  color: black;
  font-size: 14px;
  border: none;
  outline: none;
  font-weight: 500;
}
.my-tags {
  padding-left: 2rem;
  width: 100%;
  height: 2rem;
  display: flex;
  justify-content: left;
  align-items: center;
  border: none;
}
.my-tags:hover {
  cursor: pointer;
  background-color:  rgb(251 249 249) ;
}
.my-tags1 {
  padding-left: 2rem;
  width: 100%;
  height: 2rem;
  display: flex;
  justify-content: left;
  align-items: center;
}
.my-tags1:hover {
  cursor: pointer;
  background-color:  rgb(251 249 249) ;
} */
.dashboard__blacksidenav {
  width: 23rem;
  background-color: #e7e4e4;
}
.tag_inside_btn {
  height: 3rem;
  width: 7rem;
  border: none;
  outline: none;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: left;
          justify-content: left;
  -webkit-align-items: center;
          align-items: center;
  background-color: #e7e4e4;
}
.tag_inside_btn:hover {
  background-color: rgb(251 249 249);
}
.profile_name {
  padding-left: 2rem;
  color: black;
  width: 100%;
  text-decoration: none;
  list-style: none;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: left;
          flex-direction: left;
  -webkit-align-items: center;
          align-items: center;
  height: 2.3rem;
  font-size: 1.5rem;
  margin-bottom: 0.7rem;
  margin-top: -0.4rem;
}

.profile_name:hover {
  cursor: pointer;
  background-color: rgb(251 249 249);
  text-decoration: none;
}
.tag_inside_btn > span {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: left;
          justify-content: left;
  -webkit-align-items: center;
          align-items: center;
  margin-left: 3rem;
}

.tag1_text {
  font-size: 17px;
  border: none;
  outline: none;
}
.my-tags {
  padding-left: 2rem;
}
.my-tags1 {
  padding-left: 2rem;
  width: 100%;
  height: 3rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: left;
          justify-content: left;
  -webkit-align-items: center;
          align-items: center;
}
.my-tags1:hover {
  cursor: pointer;
  background-color: rgb(251 249 249);
}
input {
  padding: 0px;
}

select {
  padding: 0px;
}

.dashboard-activateTag-popup {
  font-family: "prompt", sans-serif;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  padding: 16px 24px;
  margin-bottom: 0;
  text-align: center;
}

.dashboard-verifyOtp-button {
  font-family: "prompt", sans-serif !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  background-color: #ffc107 !important;
  letter-spacing: 1px !important;
  padding: 8px 16px !important;
  border: none !important;
  outline: none !important;
  border-radius: 15px !important;
  color: white !important;
  box-shadow: 0px 2px 5px 0px #888888 !important;
}

.dashboard-verifyOtp-button:hover {
  background-color: #ebb105 !important;
}

.dashboard-otp-button {
  box-shadow: 0px 2px 5px 0px #888888 !important;
  font-family: "prompt", sans-serif !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  background-color: #fff !important;
  letter-spacing: 1px !important;
  padding: 8px 16px !important;
  border: none !important;
  outline: none !important;
  border-radius: 15px !important;
  color: black !important;
}

.dashboard-otp-buttons:hover {
  background-color: #fff !important;
}

.dashboard-input__field__dialogs {
  padding: 0.375em 0.75em;
  border: 1px solid black;
  border-radius: 15px;
}
.mat-raised-button:disabled {
  background-color: #fcbc1b;
}

.image {
  height: 120px;
  width: 120px;
  border-radius: 50%;
}

.overlay {
  height: 120px;
  width: 120px;
  background-color: black;
  margin-top: -120px;
  position: absolute;
  opacity: 0;
  border-radius: 50%;
}
.button-image {
  background-color: transparent !important;
  color: white !important;
  border: none;
  outline: none;
}
.button-image:focus {
  background-color: transparent !important;
  color: white !important;
  border: none !important;
  outline: none !important;
}

.fa-edit {
  margin-top: 48px;
  color: white;
}

.plus-icon {
  margin-top: 0px;
  color: #a1a1a1;
}

.image-upload:hover .overlay {
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1;
}

.leftside-navigation {
  list-style: none;
  margin: auto;
  margin-left: -30px;
}

#uploadPreviewActi {
  width: 120px;
  height: 120px;
  margin-top: -140px !important;
  border-radius: 50%;
  border: none;
  outline: none;
}

#uploadPreview {
  width: 120px;
  height: 120px;
  margin-top: -140px !important;
  border-radius: 50%;
  display: none;
  border: none;
  outline: none;
}

.left-side {
  border-radius: 15px 0 0 15px;
  background-color: rgb(255, 234, 177);
}

.row-underline {
  border-bottom: 5px solid #fcbc1b;
}

.input-pet-details {
  border: none;
  outline: none !important;
  border-bottom: 2px solid #e4e4e4;
  box-shadow: none;
  outline-width: 0;
  width: 200px;
  height: 20px;
  font-size: 14px;
}

.input-pet-details-1 {
  border: none;
  outline: none;
  border-bottom: 2px solid #e4e4e4;
  box-shadow: none;
  outline-width: 0;
  width: 440px;
  height: 20px;
  font-size: 14px;
}

.input-pet-details-2 {
  border: none;
  outline: none;
  border-bottom: 2px solid #a1a1a1;
  box-shadow: none;
  outline-width: 0;
  width: 200px;
  height: 20px;
  font-size: 14px;
  background-color: rgb(255, 234, 177);
}

.input-pet-details-dropdown {
  border: none;
  outline: none;
  border-bottom: 2px solid #e4e4e4;
  width: 200px;
  height: 20px;
  box-shadow: none;
  font-size: 13px;
  padding: 0px;
}

.label-petdata {
  font-size: 16px;
  color: #494949;
  font-weight: 800;
  font-family: "Lato", sans-serif;
}

.label-petdata-1 {
  font-size: 16px;
  color: #494949;
  font-weight: 800;
  font-family: "Lato", sans-serif;
}

p strong {
  font-size: 18px;
}

.color-gray {
  color: #b4b4b4;
}

::ng-deep .mat-radio-label-content {
  font-weight: 550 !important;
}

.edit-parent input:focus {
  border: none !important;
  outline: none !important;
  border-bottom: 2px solid #e4e4e4 !important;
  box-shadow: none !important;
  outline-width: 0 !important;
}

.edit-parent input:focus,
select:focus,
button:focus {
  outline: none !important;
}

input[readonly] {
  background-color: white;
}

.submit-login {
  width: 100%;
  height: 35px;
  border: none;
  outline: none;
  border-radius: 5px;
  font-weight: 500;
}

.file {
  border: none;
  outline: none;
  box-shadow: none;
  background-color: #fcbc1b;
  border-radius: 5px;
  width: 100px;
  height: 30px;
  font-size: 13px;
}

.gender-male {
  font-weight: none;
  font-size: 13px;
}

.gender-female {
  font-weight: none;
  font-size: 13px;
}

.multiple-images {
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}

.multiple-images:hover {
  -webkit-transform: scale(2.5);
          transform: scale(2.5);
}
.small__popup_ {
  margin: 1em;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  grid-gap: 1em;
  gap: 1em;
  -webkit-align-items: center;
          align-items: center;
}

.small__popup_ > h3 {
  margin-bottom: 0rem !important;
  text-wrap: nowrap;
}

.small_pop_btn {
  padding: 0.5em 1.5em;
  border: none;
  border-radius: 5px;
  background-color: #ffc107;
  font-size: 1.25rem;
}

.MuiDialogContent-root {
  overflow: visible !important;
  padding: 0 !important;
}

.close-button {
  position: absolute !important;
  top: 0rem !important; /* Position at the top */
  right: 0.2rem !important; /* Position at the right corner */
  z-index: 10 !important; /* Ensure it stays above other elements */
  background-color: transparent !important; /* Optional for a clean look */
  border: none !important;
  cursor: pointer !important;
  color: #555 !important;
  font-size: 1.5rem !important;
  transition: color 0.3s ease !important;
}

.close-button:hover {
  color: #000;
}
/* ___________________________________________________ MEDIA QUERY ___________________________________________________ */

@media only screen and (width: 1024px) {
  .leftside-navigation li {
    margin-right: 5px !important;
  }
}

@media only screen and (max-width: 990px) {
  .marginheight {
    margin-top: 0px !important;
  }
  .dashboard__blacksidenav {
    height: 100% !important;
  }
  .petparentlinks {
    margin-top: -30px !important;
    margin-left: 1px !important;
  }
  .body-login {
    margin-top: 20px !important;
  }
}

@media only screen and (max-width: 850px) {
  .body-login {
    width: 100%;
  }
}

@media only screen and (max-width: 770px) {
  .label-petdata {
    text-align: center;
  }
  .label-petdata-1 {
    text-align: center;
  }

  .gender-female {
    margin-top: -40px !important;
  }
  .below-gender-move {
    margin-top: 40px;
  }
  .leftside-buttons {
    margin-left: -50px !important;
  }
  .marginheight {
    margin-top: 0px !important;
  }
}

@media only screen and (max-width: 576px) {
  .below-gender-move {
    margin-top: 0px;
  }
  .body-login {
    border-radius: 0px;
  }
  .left-side {
    border-radius: 0px 0 0 0px;
  }
  .leftside-buttons {
    margin-left: 0px !important;
  }
  .petparentlinks {
    margin-left: -50px !important;
  }
}

@media only screen and (max-width: 510px) {
  .marginheight {
    margin-top: 30px !important;
  }

  .small__popup_ > h3 {
    font-size: 15px;
  }

  .goto-mytag-btn {
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 360px) {
  .input-pet-details {
    width: 200px;
  }

  .input-pet-details-2 {
    width: 200px;
  }

  .input-pet-details-dropdown {
    width: 200px;
  }

  .submit-login {
    width: 200px !important;
  }
}

@media only screen and (max-width: 330px) {
  .gender-female {
    margin-top: -40px !important;
  }
  .below-gender-move {
    margin-top: 40px;
  }
  .leftside-navigation {
    text-align: left !important;
  }
  .petparentlinks {
    text-align: left !important;
    margin-left: -70px !important;
  }
}

@media only screen and (max-width: 300px) {
  .input-pet-details {
    width: 170px;
  }

  .input-pet-details-2 {
    width: 170px;
  }

  .input-pet-details-dropdown {
    width: 170px;
  }
}

@media screen and (max-width: 607px) and (min-width: 576px) {
  .background {
    padding: 45px 0px 20px 20px !important;
  }
}

@media screen and (max-width: 480px) {
  .submit-login,
  .cancel-btn {
    width: 100% !important;
    margin: 10px !important;
    height: 100% !important;
  }
}

@media screen and (min-width: 48em) {
  .pet-activation-container {
    padding: 0 7em;
  }
}

@media screen and (min-width: 62.5em) {
  .petActivation-sidebar-toggle-btn-wrapper {
    display: none;
  }

  .petActivation-sidebar-container-hide {
    position: fixed;
    width: 15%;
    height: 100vh;
    left: 0;
    padding: 1rem;
  }
}

@media screen and (min-width: 75em) {
  .petActivation-right-wrapper {
    margin-left: 17em;
  }

  .myform {
    padding: 0 10%;
  }
}

@media screen and (min-width: 78.125em) {
  .petActivation-sidebar-menu-link {
    font-size: 1rem;
  }

  .petActivation-sidebar-menu-ul-links {
    font-size: 0.875rem;
  }

  .myform {
    padding: 0 20%;
  }

  .pet-activation-container {
    margin: 2% 0;
  }
}

.app {
  position: relative;
}
.app.sidebar-open .content {
  -webkit-filter: blur(5px);
          filter: blur(5px);
}
.toggle-button,
.mobile-toggle-button,
.mobileButtonStyle,
.extraButtonStyle {
  position: absolute;
  left: 12px;
  top: 12px;
  z-index: 1050;
  background: none;
  border: none;
  font-size: 24px;
  background-color: #ffffff4d;
  border: 0.75px solid #000 !important;
  border-radius: 4px;
  padding: 4px;
  /* transition: left 0.3s ease; */
}
.sidebar-icons {
  width: 1.75rem;
}
.sidebar-sub-icons {
  width: 1rem;
}
.toggle-button:focus,
.mobile-toggle-button:focus,
.mobileButtonStyle:focus,
.extraButtonStyle:focus {
  outline: none !important;
  border: 0.75px solid #000 !important;
}

.sidebar {
  position: fixed;
  top: 190px;
  left: 0;
  width: 60px;
  height: 100%;
  background-color: #ffffff;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  /* transition: width 0.3s ease; */
  z-index: 1040;
}
.app.sidebar-collapsed .toggle-button {
  left: 175px;
}
.sidebar.collapsed {
  width: 220px;
}

.sidebar-menu {
  padding: 60px 0 0 0;
}

.menu-item {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 18px 16px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
}

.menu-item span {
  display: none;
  margin-left: 10px;
}
.menu-item svg {
  width: 20px;
  height: 30px;
}
.sidebar.collapsed .menu-item span {
  display: inline;
}

.submenu {
  padding-left: 20px;
}

.submenu .menu-item {
  padding: 10px 0;
}

.sidebar-logo {
  padding: 20px;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.shop-now-button-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-top: auto;
  padding: 26rem 3rem 0rem 3rem;
}

.shop-now-button {
  border: 2px solid black;
  background-color: white;
  color: black;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  text-transform: uppercase;
  transition: background-color 0.3s, color 0.3s;
}

.shop-now-button:hover {
  background-color: black;
  color: white;
}

@media (max-width: 768px) {
  .toggle-button {
    display: none;
  }

  .mobile-toggle-button {
    left: -17px;
    top: 40px;
    padding: 0px;
  }
  .mobileButtonStyle {
    left: 0px;
    top: 40px;
    padding: 0px;
  }
  .extraButtonStyle {
    left: 35px;
    top: 70px;
    padding: 0px;
  }
  .app.sidebar-collapsed .mobile-toggle-button {
    left: 165px;
    top: 40px;
  }
  .app.sidebar-collapsed .mobileButtonStyle {
    left: 195px;
    top: 40px;
  }
  .app.sidebar-collapsed .extraButtonStyle {
    left: 195px;
    top: 65px;
  }
  .sidebar {
    top: 140px !important;
    width: 0;
    height: 100%;
    /* transition: width 0.3s ease; */
  }

  .sidebar.collapsed {
    width: 230px;
  }

  .app.sidebar-collapsed .sidebar {
    /* top: 0; */
    left: 0;
  }
}

@media (min-width: 361px) and (max-width: 768px) {
  .sidebar {
    top: 112px !important;
  }

  .app.sidebar-collapsed .sidebar {
    top: 116px !important;
  }
}
/* Main Container */
.editPetDetails-main-wrapper {
  width: 100%;
  background-color: hsla(0, 0%, 78%, 0.5058823529411764);
}

.editPetDetails-left-wrapper {
  position: relative;
}

.editPetDetails-sidebar-container {
  width: 100%;
  height: 100vh;
  z-index: 800;
  position: absolute;
  background-color: whitesmoke;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  left: 0;
  transition: 350ms;
}

.editPetDetails-sidebar-container-hide {
  width: 100%;
  height: 100vh;
  z-index: 800;
  position: absolute;
  background-color: whitesmoke !important;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  left: -100%;
  transition: 350ms;
}

.editPetDetails-sidebar-toggle-btn-wrapper {
  padding: 1rem;
  width: 100%;
}

.editPetDetails-sidebar-toggle-btn {
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
}

.editPetDetails-sidebar-menu-link {
  background-color: transparent;
  border: none;
  padding: 1rem;
  text-align: left;
  font-size: 1.21875rem;
}

.editPetDetails-sidebar-menu-ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.editPetDetails-sidebar-menu-ul-hide {
  display: none;
}

.editPetDetails-sidebar-menu-ul-links {
  border: none;
  background-color: transparent;
  padding: 1rem 1rem 1rem 2rem;
}

.editPetDetails-form-container-top {
  /* border: 1px solid brown; */
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  grid-gap: 2rem;
  gap: 2rem;
  padding: 1rem;
  margin-top: 0rem;
}

.editPetDetails-user-img {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  border: 1px solid lightgray;
  border-radius: 50%;
  /* padding: 1.5rem; */
  width: 12rem;
  height: 12rem;
}

.editPetDetails-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.editPetDetails-input-user-img {
  position: absolute;
  display: none;
  top: 13em;
  left: -1.5rem;
}

.editPetDetails-edit-button-image,
.editPetDetails-delete-button-image {
  border-radius: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  /* font-size: 1.3rem; */
  border: 1px solid lightgray;
  padding: 0.3rem;
  z-index: 20;
  color: black;
  cursor: pointer;
}
.editPetDetails-edit-button-image:hover,
.editPetDetails-delete-button-image:hover {
  color: #fcbc1b;
  background-color: white;
}

.editPetDetails-edit-button-image {

  top: 12.5em;
  left: -1rem;
  border-radius: 7px;
  padding: 9px 14px;
  display: -webkit-flex;
  display: flex;
  grid-gap: 4px;
  gap: 4px;
}

.editPetDetails-delete-button-image {

  top: 12.5em;
  right: -1rem;
  border-radius: 7px;
  padding: 9px 14px;
  display: -webkit-flex;
  display: flex;
  grid-gap: 4px;
  gap: 4px;
  background-color: white;
}

.editPetDetails-form {
  padding: 1rem;
}

.editPetDetails-input-wrapper {
  width: 49%;
}

.editPetDetails-input-tag {
  padding: 1rem 2rem !important;
  border: 1px solid black;
  border-radius: 5px;
}

.pet-type-span,
.pet-breed-span,
.petdata-span,
.pet-blood-grp-span,
.pet-gender-cc,
.pet-birth-span {
  margin: 1rem 0;
}

/* -webkit-appearance: none; */
/* -moz-appearance: none; */
/* appearance: none; */

.editPetDetails-select-tag {
  background-color: white;
  border: 1px solid black;
  padding: 1rem 2rem !important;
  border-radius: 5px !important;
  /* width: 100%; */
}

.editPetDetails-btn-row {
  display: -webkit-flex;
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
}

.body-login {
  width: 85%;
  border-radius: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
  margin-top: 15px;
}
.image-upload {
  width: 120px;
  height: 120px;
  margin: auto !important;
}
.image {
  height: 120px;
  width: 120px;
  border-radius: 50%;
}

.overlay {
  height: 120px;
  width: 120px;
  background-color: black;
  margin-top: -120px;
  position: absolute;
  opacity: 0;
  border-radius: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.dashboard__blacksidenav {
  width: 23rem;
  background-color: #e7e4e4;
}
.close-button {
  position: absolute !important;
  top: 0rem !important; /* Position at the top */
  right: 0.2rem !important; /* Position at the right corner */
  z-index: 10 !important; /* Ensure it stays above other elements */
  background-color: transparent !important; /* Optional for a clean look */
  border: none !important;
  cursor: pointer !important;
  color: #555 !important;
  font-size: 1.5rem !important;
  transition: color 0.3s ease !important;
}

.close-button:hover {
  color: #000;
}
.tag1_text {
  font-size: 18px;
  border: none;
  outline: none;
}
.my-tags {
  padding-left: 2rem;
}
.my-tags1 {
  padding-left: 2rem;
  width: 100%;
  height: 3rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: left;
          justify-content: left;
  -webkit-align-items: center;
          align-items: center;
}
.my-tags1:hover {
  cursor: pointer;
  background-color: rgb(251 249 249);
}

#choose-profile-imageActi {
  display: none;
}

#choose-profile-imageActi-2 {
  display: none;
}

.mat-raised-button:disabled {
  background-color: #fcbc1b;
}

.image {
  height: 120px;
  width: 120px;
  border-radius: 50%;
  text-align: center;
}

.overlay {
  height: 120px;
  width: 120px;
  background-color: black;
  margin-top: -120px;
  position: absolute;
  opacity: 0;
  border-radius: 50%;
}
.button-image {
  background-color: transparent;
  border: none;
  outline: none;
}
.fa-edit {
  margin-top: 48px;
  color: white;
}

.plus-icon {
  margin-top: 0px;
  color: #a1a1a1;
}

.image-upload:hover .overlay {
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1;
}

.pd-leftside-navigation {
  list-style: none;
  margin: auto;
  /* margin-left: -30px; */
}

#uploadPreviewActi {
  width: 120px;
  height: 120px;
  margin-top: -148px !important;
  border-radius: 50%;
  border: none;
  outline: none;
}

#uploadPreview {
  width: 120px;
  height: 120px;
  margin-top: -148px !important;
  border-radius: 50%;
  display: none;
  border: none;
  outline: none;
}

.left-side {
  border-radius: 15px 0 0 15px;
  background-color: rgb(255, 234, 177);
}



.input-pet-details {
  border: none;
  outline: none !important;
  border-bottom: 2px solid #e4e4e4;
  box-shadow: none;
  outline-width: 0;
  width: 200px;
  height: 20px;
  font-size: 14px;
}

.input-pet-details-1 {
  border: none;
  outline: none;
  border-bottom: 2px solid #e4e4e4;
  box-shadow: none;
  outline-width: 0;
  width: 440px;
  height: 20px;
  font-size: 14px;
}

.label-petdata {
  font-size: 16px;
  color: #494949;
  font-weight: 800;
  font-family: "prompt, sans-serif";
}

.label-petdata-1 {
  font-size: 16px;
  color: #494949;
  font-weight: 800;
  font-family: "prompt, sans-serif";
}

p strong {
  font-size: 18px;
}

.color-gray {
  color: #b4b4b4;
}

::ng-deep .mat-radio-label-content {
  font-weight: 550 !important;
}
.add_img {
  text-decoration: none;
  font-size: 20px;
  color: #737373;
}
.add_img:hover {
  text-decoration: none;
  color: #737373;
  cursor: pointer;
}
.edit-parent input:focus {
  border: none !important;
  outline: none !important;
  border-bottom: 2px solid #e4e4e4 !important;
  box-shadow: none !important;
  outline-width: 0 !important;
}

.edit-parent input:focus,
select:focus,
button:focus {
  outline: none !important;
}

input[readonly] {
  background-color: white;
}

.submit-login {
  width: 100%;
  height: 40px;
  border: none;
  outline: none;
  border-radius: 5px;
  font-weight: 500;
}

.file {
  border: none;
  outline: none;
  box-shadow: none;
  background-color: #fcbc1b;
  border-radius: 5px;
  width: 100px;
  height: 30px;
  font-size: 13px;
}

.gender-male {
  font-weight: none;
  font-size: 13px;
}

.gender-female {
  font-weight: none;
  font-size: 13px;
}

@media only screen and (max-width: 990px) {
  .marginheight {
    margin-top: 0px !important;
  }
  .dashboard__blacksidenav {
    height: 100% !important;
  }
  .petparentlinks {
    margin-top: -30px !important;
    margin-left: 1px !important;
  }
  .body-login {
    margin-top: 20px !important;
  }
}

@media only screen and (max-width: 850px) {
  .body-login {
    width: 100%;
  }
  .pd-leftside-navigation {
    margin-left: -35px !important;
  }
}

@media only screen and (max-width: 770px) {
  .label-petdata {
    text-align: center;
  }
  .label-petdata-1 {
    text-align: center;
  }

  .gender-female {
    margin-top: -40px !important;
  }
  /* .below-gender-move {
    margin-top: 40px;
  } */
  .pd-leftside-navigation {
    margin-left: 15px !important;
  }
  .marginheight {
    margin-top: 0px !important;
  }
}

@media only screen and (max-width: 565px) {
  .pd-leftside-navigation {
    margin-left: -50px !important;
  }
}

@media only screen and (max-width: 576px) {
  /* .below-gender-move {
    margin-top: 0px;
  } */
  .body-login {
    border-radius: 0px;
  }
  .left-side {
    border-radius: 0px 0 0 0px;
  }
  .leftside-buttons {
    margin-left: 0px !important;
  }
  .petparentlinks {
    margin-left: -50px !important;
  }
}

@media only screen and (max-width: 510px) {
  .marginheight {
    margin-top: 30px !important;
  }
}

@media only screen and (max-width: 360px) {
  .input-pet-details {
    width: 200px;
  }

  /* .input-pet-details-2 {
    width: 200px;
  } */

  /* .input-pet-details-dropdown {
    width: 200px;
  } */
  .submit-login {
    width: 200px !important;
  }
}

@media only screen and (max-width: 330px) {
  .gender-female {
    margin-top: -40px !important;
  }
  /* .below-gender-move {
    margin-top: 40px;
  } */
  .leftside-buttons {
    width: 160px !important;
  }
  .pd-leftside-navigation {
    text-align: left !important;
  }
  .petparentlinks {
    text-align: left !important;
    margin-left: -70px !important;
  }
}

@media only screen and (max-width: 300px) {
  .input-pet-details {
    width: 170px;
  }

  /* .input-pet-details-2 {
    width: 170px;
  } */

  /* .input-pet-details-dropdown {
    width: 170px;
  } */
}

/*  Media Queries Start */
@media only screen and (max-width: 1070px) and (min-width: 647px) {
  .marginheight {
    margin-top: 20px !important;
  }
}

@media screen and (min-width: 1010px) {
  .image-upload {
    margin-left: 0px !important;
  }

  /* .mynavigator button {
    padding-left: 25px !important;
    padding-right: 25px !important;
  } */
}

@media screen and (min-width: 853px) {
  .image-upload {
    margin-left: 0px !important;
  }
}

@media (max-width: 577px) {
  .background {
    padding: 45px 0px 0px 0px !important;
  }
}

@media screen and (max-width: 500px) and (min-width: 425px) {
  .image-upload {
    margin-right: 0px !important;
    margin-left: -3rem;
  }
  /* .mynavigator {
    margin-left: 0px !important;
  } */
}

@media screen and (max-width: 607px) and (min-width: 576px) {
  .myform col {
    margin-bottom: 5px !important;
  }

  /* .background {
    padding: 45px 0px 20px 20px !important;
  } */
}

@media screen and (max-width: 480px) {
  .submit-login,
  .cancel-btn {
    width: 100% !important;
    margin: 10px !important;
    height: 100% !important;
  }
}

@media screen and (min-width: 62.5em) {
  .editPetDetails-sidebar-toggle-btn-wrapper {
    display: none;
  }

  .editPetDetails-sidebar-container-hide {
    position: fixed;
    width: 15%;
    height: 100vh;
    left: 0;
    padding: 1rem;
  }
}

@media screen and (width: 64em) {
  .editPetDetails-right-wrapper {
    padding: 0 11% 0 23%;
  }
}

@media screen and (min-width: 75em) {
  /* .editPetDetails-form-container {
    width: 38em;
    margin: 0 24rem;
  } */
}

@media screen and (min-width: 78.125em) {
  .editPetDetails-sidebar-menu-link {
    font-size: 1rem;
  }

  .editPetDetails-sidebar-menu-ul-links {
    font-size: 0.875rem;
  }
}

.editPetDetails-form-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: center;
          align-items: center;
}

/* Main Right Container */
.editPetDetails-right-wrapper {
  background-color: white;
  border-radius: 10px;
  width: 90%;
  margin: auto;
  padding: 15px;
}

.two-field-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  padding: 8px 0px;
}
@media screen and (max-width: 480px) {
  .two-field-wrapper {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .editPetDetails-input-wrapper {
    width: 100%;
    margin: 8px 0px;
  }
}

.MuiFormControl-root {
  width: 100% !important;
}

@media only screen and (min-width: 660px) {
  .editPetDetails-form-container {
    -webkit-flex-direction: row;
            flex-direction: row;
  }
  .editPetDetails-form-container-top {
    margin-top: -4.5rem;
  }
}
@media screen and (min-width: 1000px) and (max-width: 1200px) {
  .editPetDetails-right-wrapper {
    width: 45rem;
    margin: 3rem 17rem;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .editPetDetails-right-wrapper {
    width: 50rem;
    margin: 3rem 22rem;
  }
}
@media screen and (min-width: 1400px) {
  .editPetDetails-right-wrapper {
    width: 50rem;
    margin: 3rem 28rem;
  }
}

/* breacrumbs css */
.mui-breadcrumb li::before {
  content: none;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .editPetDetails-main-wrapper {
    padding: 5rem 0 0rem 0;
  }
}
@media screen and (min-width: 768px) {
  .editPetDetails-main-wrapper {
    padding: 9rem 0 4rem 0;
  }
}
@media screen and (max-width: 360px) {
  .editPetDetails-main-wrapper {
    width: 100%;
  }
  .editPetDetails-right-wrapper {
    padding: 2.5rem;
  }
}

.gender-lebal {
  margin-left: 14px !important;
  font-size: 12px !important;
  margin-bottom: 0px !important;
}
.MuiFormGroup-row {
  margin-left: 14px !important;
}

.MuiFormGroup-root {
  -webkit-flex-direction: row !important;
          flex-direction: row !important;
  -webkit-flex-wrap: nowrap !important;
          flex-wrap: nowrap !important;
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  z-index: 9999;
}


/* Main Container */
.editParentDetails-main-wrapper {
  background-color: hsla(0, 0%, 78%, 0.5058823529411764);
  width: 100%;
}

.editParentDetails-left-wrapper {
  position: relative;
}

.editParentDetails-sidebar-container {
  width: 100%;
  height: 100vh;
  z-index: 800;
  position: absolute;
  background-color: #fff;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  left: 0;
  transition: 350ms;
}

.editParentDetails-sidebar-container-hide {
  /* width: 100%; */
  height: 100vh;
  z-index: 800;
  position: absolute;
  background-color: whitesmoke;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  left: -100%;
  transition: 350ms;
  margin: -2rem 0 0 0;
}

.editParentDetails-sidebar-toggle-btn-wrapper {
  padding: 1rem;
  width: 100%;
  margin: -1.5rem 0 0 -2rem;
}

.editParentDetails-sidebar-toggle-btn {
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
}

.editParentDetails-sidebar-menu-link {
  background-color: transparent;
  border: none;
  padding: 1rem;
  text-align: left;
  font-size: 1.21875rem;
}

.editParentDetails-sidebar-menu-ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.editParentDetails-sidebar-menu-ul-hide {
  display: none;
}

.editParentDetails-sidebar-menu-ul-links {
  border: none;
  background-color: transparent;
  padding: 1rem 1rem 1rem 2rem;
}

.editParentDetails-form-container-top {
  /* border: 1px solid brown; */
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  padding: 1rem;
}

.editParentDetails-user-img {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.editParentDetails-image {
  height: 10em;
  width: 10em;
  border-radius: 50%;
}

.editParentDetails-input-user-img {
  position: absolute;
  display: none;
}

.editParentDetails-preview-img {
  /* border: 1px solid red; */
  height: 7.5em;
  width: 7.5em;
  border-radius: 50%;
  position: absolute;
}

.editParentDetails-edit-button-image,
.editParentDetails-delete-button-image {
  border-radius: 7px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  border: 1px solid gray;
  padding: 0.3rem;
  z-index: 20;
  color: #000;
  padding: 9px 14px;
}

.editParentDetails-edit-button-image:hover {
  color: #fcbc1b;
}

.editParentDetails-delete-button-image:hover {
  color: #fcbc1b;
}

.editParentDetails-edit-button-image {
  position: absolute;
  top: 4em;
  left: 12em;
}

.editParentDetails-delete-button-image {
  position: absolute;
  top: 7em;
  left: 12em;
  background-color: white;
}

.editParentDetails-form {
  padding: 1rem;
}

.editParentDetails-input-wrapper {
  margin: 2px 4px;
  width: 28vw;
}

.editParentDetails-input-tag {
  padding: 1rem 2rem !important;
  border: 1px solid black;
  border-radius: 5px;
}
.gender-span,
.cc-span {
  margin: 1rem 0;
}
.editParentDetails-select-tag {
  background-color: white;
  border: 1px solid black;
  padding: 1rem 2rem !important;
  border-radius: 5px !important;
  width: 100%;
}
.editParentDetails-btn-row {
  display: -webkit-flex;
  display: flex;
  grid-gap: 1.9em;
  gap: 1.9em;
}

.body-login {
  width: 85%;
  border-radius: 15px;
  box-shadow: 0 4px 8px 0 rgba(20, 12, 12, 0.2),
    0 6px 10px 0 rgba(0, 0, 0, 0.19);
  margin-top: 15px;
}
.image {
  height: 120px;
  width: 120px;
  border-radius: 50px;
}
.close-button {
  position: absolute !important;
  top: 0rem !important; /* Position at the top */
  right: 0.2rem !important; /* Position at the right corner */
  z-index: 10 !important; /* Ensure it stays above other elements */
  background-color: transparent !important; /* Optional for a clean look */
  border: none !important;
  cursor: pointer !important;
  color: #555 !important;
  font-size: 1.5rem !important;
  transition: color 0.3s ease !important;
}

.close-button:hover {
  color: #000;
}
.overlay {
  height: 120px;
  width: 120px;
  background-color: black;
  margin-top: -120px;
  position: absolute;
  opacity: 0;
  border-radius: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.button-image {
  background-color: transparent !important;
  color: white !important;
  border: none;
  outline: none;
}
.button-image:focus {
  background-color: transparent !important;
  color: white !important;
  border: none !important;
  outline: none !important;
}
.dashboard__blacksidenav {
  width: 23rem;
  background-color: #e7e4e4;
  position: -webkit-sticky;
  position: sticky;
  top: 5rem;
}
.tag1_text {
  font-size: 17px;
  border: none;
  outline: none;
}
.my-tags {
  padding-left: 2rem;
}
.my-tags1 {
  padding-left: 2rem;
  width: 100%;
  height: 3rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: left;
          justify-content: left;
  -webkit-align-items: center;
          align-items: center;
}
.my-tags1:hover {
  cursor: pointer;
  background-color: rgb(251 249 249);
}
.background {
  min-height: 100% !important;
}
.image-upload {
  width: 120px;
  height: 120px;
  padding: 2rem 0rem 0rem 5rem;
}

#choose-profile-imageActi {
  display: none;
}

.image {
  height: 120px;
  width: 120px;
  border-radius: 50%;
  text-align: center !important;
  margin: auto !important;
}

.overlay {
  height: 120px;
  width: 120px;
  background-color: black;
  margin-top: -120px;
  position: absolute;
  opacity: 0;
  border-radius: 50%;
}
.button-image {
  background-color: transparent;
  border: none;
  outline: none;
}
.fa-edit {
  margin-top: 48px;
  color: white;
}
.plus-icon {
  margin-top: 0px;
  color: #a1a1a1;
}
.image-upload:hover .overlay {
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1;
}
#uploadPreviewActi {
  width: 120px;
  height: 120px;
  margin-top: -140px !important;
  border-radius: 50%;
  border: none;
  outline: none;
}
#uploadPreview {
  width: 120px;
  height: 120px;
  margin-top: -140px !important;
  border-radius: 50%;
  display: none;
  border: none;
  outline: none;
}

.label-petdata {
  font-size: 16px;
  color: #494949;
  font-weight: 800;
  font-family: "prompt, sans-serif";
}
p strong {
  font-size: 18px;
}

.color-gray {
  color: #b4b4b4;
}

::ng-deep .mat-radio-label-content {
  font-weight: 550 !important;
}
.submit-btn-editPet,
.cancel-btn-editPet {
  width: 100%;
  height: 40px !important;
  border: none;
  outline: none;
  border-radius: 5px;
  font-weight: 500;
}
.submit-btn-editPet {
  background-color: #fcbc1b !important;
}
.cancel-btn-editPet {
  background-color: white !important;
}
.cancel-btn-editPet:hover {
  background-color: #fcbc1b !important;
}

.file {
  border: none;
  outline: none;
  box-shadow: none;
  background-color: #fcbc1b;
  border-radius: 5px;
  width: 100px;
  height: 30px;
  font-size: 13px;
}

.gender-male {
  font-weight: none;
  font-size: 13px;
}

.gender-female {
  font-weight: none;
  font-size: 13px;
}

@media only screen and (max-width: 990px) {
  .marginheight {
    margin-top: 0px !important;
  }
  .dashboard__blacksidenav {
    height: 100% !important;
  }
  .petparentlinks {
    margin-top: -30px !important;
    margin-left: 1px !important;
  }
  .body-login {
    margin-top: 20px !important;
  }
}

@media only screen and (max-width: 850px) {
  .body-login {
    width: 100%;
  }
}

@media only screen and (max-width: 770px) {
  .label-petdata {
    text-align: center;
  }
  /* .label-petdata-1 {
    text-align: center;
  } */

  .gender-female {
    margin-top: -40px !important;
  }
  .below-gender-move {
    margin-top: 40px;
  }
  /* .leftside-navigation {
    margin-left: 15px !important;
  } */
  .marginheight {
    margin-top: 0px !important;
  }
}

@media only screen and (max-width: 576px) {
  .below-gender-move {
    margin-top: 0px;
  }
  .body-login {
    border-radius: 0px;
  }
  /* .left-side {
    border-radius: 0px 0 0 0px;
  } */
  .leftside-buttons {
    margin-left: 0px !important;
  }
  .petparentlinks {
    margin-left: -50px !important;
  }
}

@media only screen and (max-width: 510px) {
  .marginheight {
    margin-top: 30px !important;
  }
}

@media only screen and (max-width: 330px) {
  .gender-female {
    margin-top: -40px !important;
  }
  .below-gender-move {
    margin-top: 40px;
  }
  .leftside-buttons {
    width: 160px !important;
  }
  .petparentlinks {
    text-align: left !important;
    margin-left: -70px !important;
  }
}
/* Media Queries Start */
@media only screen and (max-width: 991px) {
  .dashboard__blacksidenav {
    width: 100%;
  }
  .left_pannel_options {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
  }
  .my_tags_text {
    display: -webkit-flex;
    display: flex;
    width: 100%;
  }
  .my-tags {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
  }
  .list_links {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100%;
    margin: 0px;
  }
  /* .links_button {
    display: flex;
    flex-direction: column;
    width: 100%;
  } */
  .mytags1buttons {
    width: 100%;
  }
  .image-upload {
    padding: 2rem 1rem;
  }
}

@media screen and (min-width: 1010px) {
  .image-upload {
    margin-left: 2rem !important;
  }

  /* .mynavigator button {
    padding-left: 25px !important;
    padding-right: 25px !important;
  } */
}

@media screen and (min-width: 853px) {
  .image-upload {
    margin-left: 2rem !important;
  }
}

@media screen and (max-width: 500px) and (min-width: 425px) {
  .image-upload {
    margin-right: 12rem !important;
    /* margin-left: -3rem; */
  }
}

@media screen and (min-width: 62.5em) {
  .editParentDetails-sidebar-toggle-btn-wrapper {
    display: none;
  }

  .editParentDetails-sidebar-container-hide {
    position: fixed;
    width: 15%;
    height: 100vh;
    left: 0;
    padding: 1rem;
    color: whitesmoke;
  }
}

@media screen and (min-width: 78.125em) {
  .editParentDetails-sidebar-menu-link {
    font-size: 1rem;
  }

  .editParentDetails-sidebar-menu-ul-links {
    font-size: 0.875rem;
  }
}

/* @media screen and (min-width: 75em) {
  .editParentDetails-form-container {
    width: 38em;
    margin: 0 14rem;
  }
} */

.editParentDetails-form-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

/* Main Right Container */
.editParentDetails-right-wrapper {
  border-radius: 10px;
  width: 90%;
  margin: auto;
  padding: 15px;
  background-color: #fff;
}

@media only screen and (min-width: 660px) {
  .editParentDetails-form-container {
    -webkit-flex-direction: row;
            flex-direction: row;
  }
}

.two-field-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

#demo-row-radio-buttons-group-label1 {
  margin: 0 0 0 14px !important;
  font-size: 12px !important;
}

@media screen and (max-width: 480px) {
  .two-field-wrapper {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .editParentDetails-input-wrapper {
    margin: 4px 0px;
    width: 100%;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1200px) {
  .editParentDetails-right-wrapper {
    width: 70vw;
    margin-left: 15rem;
    padding: 1rem;
  }
  .editParentDetails-sidebar-container-hide {
    width: 20vw;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1300px) {
  .editParentDetails-right-wrapper {
    width: 52rem;
    margin-left: 16rem;
    padding: 1rem;
  }
}
@media screen and (min-width: 1300px) and (max-width: 1600px) {
  .editParentDetails-right-wrapper {
    width: 65rem;
    margin-left: 15rem;
    padding: 1rem;
  }
}
@media screen and (min-width: 1600px) and (max-width: 1800px) {
  .editParentDetails-right-wrapper {
    width: 70rem;
    margin-left: 20rem;
    padding: 1rem;
  }
}
@media screen and (min-width: 1800px) and (max-width: 2000px) {
  .editParentDetails-right-wrapper {
    width: 80rem;
    margin-left: 24rem;
    padding: 1rem;
  }
}
@media screen and (min-width: 2000px) and (max-width: 2300px) {
  .editParentDetails-right-wrapper {
    width: 85rem;
    margin-left: 25rem;
    padding: 1rem;
  }
}

@media screen and (min-width: 2300px) {
  .editParentDetails-right-wrapper {
    width: 95rem;
    margin-left: 27rem;
    padding: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .editParentDetails-main-wrapper {
    padding: 5rem 1.2rem 3rem 1.2rem;
  }
}
@media screen and (min-width: 769px) {
  .editParentDetails-main-wrapper {
    padding: 11rem 2rem 4rem 2rem;
  }
}
@media screen and (max-width: 360px) {
  .editParentDetails-main-wrapper {
    width: 100%;
  }
  .editParentDetails-right-wrapper {
    padding: 2.5rem;
    width: 100%;
    margin-bottom: 7rem;
  }
  .editParentDetails-sidebar-container-hide {
    left: -115%;
  }
}
@media screen and (min-width: 1024px) {
  /* .editParentDetails-main-wrapper{
    height: 100vh;
  } */
}

.MuiFormGroup-root {
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
}


.MuiFormGroup-row {
  margin-left: 14px;
}


.calendar_container_det{
    text-align: center;
    width: 100%;
    padding-top: 1rem;
    border-top: 1px solid rgb(211, 211, 211);
}
.Med_tit{
    font-size: 1.3rem;
    margin-bottom: 1rem;
    padding: 10px;
    border: 1px solid gray;
    display: inline-block;
    border-radius: 5px;
    box-shadow: 2px 2px 7px gray;
}
.inside_detail_con{
    display: -webkit-flex;
    display: flex ;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}
.summary_container{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgb(189, 187, 187);
    padding: 15px 15px;
    margin-bottom: 20px;
}

.table1{
    margin-left: 30px !important;
}

.table1 td{
    text-align: center;
}
.table1 th{
    text-align: center;
}
#EventLog b {
    color: #388e3c;
  }
  
  hr {
    margin: 1px 10px 1px 0px;
    border-top: 1px solid #eee;
  }
  /* custom code end*/
  
  #_dialog_wrapper{
    width: 590px !important;
  }

  .e-appointment{
    border-radius: 3px !important;
    margin-left: -13px !important;
    margin-top: 10px !important;
    height: 25px !important;
    border: 1px solid black !important;
    box-shadow: 1px 1px 5px gray;
    background-color: rgb(219, 92, 69) !important;
  }

  .e-appointment-details{
    cursor: pointer;
    border-radius: 3px;
    padding-left: 1px;
  }

  .e-appointment-details:hover{
    background-color: #d1f140;
    color: black;
    transition: all 0.2s;
  }

  .e-schedule .e-month-view .e-content-table {
    height: 90% !important;
  }

  .e-schedule .e-schedule-toolbar .e-icon-schedule-print::before {
    content: '\e973';
    margin-left: -30px !important;
  }
  
  .e-schedule .e-schedule-toolbar .e-icon-schedule-pdf::before {
    content: '\e7c8';
    margin-left: -30px !important;
  }
  
  .custom-field-row {
    margin-bottom: 20px;
    margin-left: -30px !important;
  }
  
  .multi-prop div {
    padding-left: 0;
    padding-top: 0;
    margin-left: -30px !important;
  }

.calendarview{
    margin-left: -30px !important;
}

.e-schedule .e-month-view .e-content-wrap {
  display: block !important;
}

table{
  width: 100% !important;
  margin-left: 0 !important;
}

.e-bigger.e-small .e-calendar, .e-calendar {
  max-width: 320px !important;
  min-width: 275px !important;
}

/* Media Queries */

@media only screen and (max-width:768px) {

  .control-section{
    margin: 0px !important;
    width:  100% !important;
  }

  .control-wrapper{
    width: 100% !important;
  }

  .myschedule{
    margin: 0px !important;
    padding: 10px !important;
    width: 100% !important;

    /* background-color:  lawngreen !important; */
  }

  .calendarview{
    margin-right: 30px !important;
  }
  .myviewdiective{
    /* padding-left: 0px !important; */
    
    margin-left: -100px !important;
    /* background-color: lawngreen !important; */
  }

  .schedule-control-section{
    margin-left: -150px !important;
    padding-left: -100px !important;
  }

  .innerdirective{
    margin-left: -100px !important;
  }

  .e-content-table{
    margin-left: 70px !important;
    width: 100%;
    border: 1px solid black !important;
  }

  .e-outer-table{
    width: 90% !important;
  }

  @media only screen and (max-width:400px){
    .e-schedule-table{
      margin-left: 60px !important;
      width: 100%;
    }
  }
  @media only screen and (max-width:300px){
    .e-schedule-table{
      margin-left: 50px !important;
      width: 100%;
    }
    .e-header-icon-wrapper{
      padding-left: 40px !important;
    }
    .e-subject-wrap{
      padding-left: 60px !important;
    }
    .e-date-time{
      padding-left: 30px !important;
    }
  }
}

.main-wrapper {
  width: 100%;
  padding: 9rem 0rem 6rem 0rem;
}
.close-button {
  position: absolute !important;
  top: 0rem !important; /* Position at the top */
  right: 0.2rem !important; /* Position at the right corner */
  z-index: 10 !important; /* Ensure it stays above other elements */
  background-color: transparent !important; /* Optional for a clean look */
  border: none !important;
  cursor: pointer !important;
  color: #555 !important;
  font-size: 1.5rem !important;
  transition: color 0.3s ease !important;
}

.close-button:hover {
  color: #000;
}
@media screen and (max-width: 768px) {
  .main-wrapper {
    padding: 0rem 0rem 17rem 0rem;
  }
}
@media screen and (max-width: 360px) {
  .main-wrapper {
    padding: 0rem 0rem 2rem 0rem;
  }
}
.left-wrapper {
  position: relative;
}

.sidebar-container {
  width: 100%;
  height: 100vh;
  z-index: 800;
  position: absolute;
  background-color: #e6e5e3;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  left: 0;
  transition: 350ms;
}

.sidebar-container-hide {
  width: 100%;
  height: 100vh;
  z-index: 800;
  position: absolute;
  background-color: whitesmoke;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  left: -100%;
  transition: 350ms;
}

.sidebar-toggle-btn-wrapper {
  padding: 1rem;
  width: 100%;
}

.sidebar-toggle-btn {
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
}

.sidebar-menu-link {
  background-color: transparent;
  border: none;
  padding: 1rem;
  text-align: left;
  font-size: 1.21875rem;
}

.sidebar-menu-ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.sidebar-menu-ul-hide {
  display: none;
}

.sidebar-menu-ul-links {
  border: none;
  background-color: transparent;
  padding: 1rem 1rem 1rem 2rem;
}

.search-sort-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  grid-gap: 1em;
  gap: 1em;
}
.search-addrecord-div {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 999;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
}
.search-addrecord-div-hideShow {
  display: none;
}
.floating-hideShow-btn {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}

.medicalDashboard-add-new-record-btn {
  background-color: #ffc107;
  padding: 0.5rem 0.7rem !important;
  border: none;
  border-radius: 15px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  grid-gap: 0.4375em;
  gap: 0.4375em;
}

.medicalDashboard-add-new-record-btn:hover {
  background-color: #e2aa03;
}

.hide {
  left: -100%;
  transition: 850ms;
}
.hide-search {
  left: 100rem;
  transition: all ease-in-out 1000ms;
}


.collapse {
  display: none;
}

.background {
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
  margin-top: -3rem;
}

.medicaldashboard-search-input {
  padding: 1.2em 1em;
  border: 1px solid #c4bcbc;
  border-radius: 50px;
  outline: none;
  position: absolute;
  top: 71.4px;
  right: -1px;
}

.sort-filter-div {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-align-items: center;
          align-items: center;
}

.med_con {
  margin-top: -0.3rem;
}

.pet-information-active {
  float: left;
  width: 100%;
  margin-top: 30px;
  /* background-color: rgba(231, 227, 227, 0.6); */
  background-color: white;
  border-radius: 15px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 10px;
}

.dropbtn {
  font-size: 18px !important;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  margin-left: -35px;
  margin-top: 5px;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-item-1 {
  width: 100% !important;
}

.dropdown:hover {
  display: block;
}

.dropdown {
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
}

.ftr_opn {
  background-color: #e7e4e4;
  cursor: pointer;
  width: 250px;
  height: 30px;
}

option {
  background-color: #e7e4e4;
  cursor: pointer;
  width: 250px;
  height: 30px;
}

.dropdown-content > a {
  width: inherit;
  color: black;
  padding: 120px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content .dropdown-item-1:hover {
  background-color: #dddada;
  color: rgb(0, 0, 0);
  text-decoration: none;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: inherit;
}

.leftside-details {
  border: 2px solid rgb(8, 73, 194);
  border-radius: 15px;
  padding: 20px;
}

.leftside-blocks1 {
  height: 80px;
  width: 25px;
  border: 2px solid rgb(140, 140, 143);
  margin-right: 25px;
  margin-left: 30px;
}

.leftside-blocks2 {
  height: 80px;
  width: 25px;
  border: 2px solid rgb(140, 140, 143);
  margin-right: 30px;
}

.rightside-blocks {
  width: 30%;
  height: 100px;
  border-radius: 10px;
  background-color: #ffffff;
  border: 3px solid #cccccc;
  margin: 10px;
  display: inline-block;
  box-sizing: border-box;
  /* border: 1px solid red;
  height: 100px;
  width: 33%;
  margin: 10px;
  border: 2px solid rgb(175, 175, 175);
  background-color: white;
  padding-top: 5px !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between; */
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.rightside-blocks-image {
  margin-left: -13px;
}

.rightside-vaccinations {
  font-size: 13px;
  margin-top: 30px;
}

.rightside-blocks-middle {
  font-size: 40px;
  font-weight: 500;
  padding: 10px 0;
}

.notification-text {
  font-size: 11px;
}

.fa-bell {
  margin-left: -5px;
  margin-top: 10px;
}

.rightside-button {
  width: 100%;
  border: none;
  outline: none;
  box-shadow: none;
  text-align-last: center;
}

.rightside-button-1 {
  width: 100%;
  border: none;
  outline: none;
  box-shadow: none;
}

.rightside-button-2 {
  width: 100%;
  border: none;
  outline: none;
  box-shadow: none;
}

.rightside-button-add-record {
  width: 100%;
  border: none;
  outline: none;
  box-shadow: none;
}

.rightside-medical-details {
  border: 2px solid rgb(202, 202, 202);
  margin-top: 30px;
  border-radius: 50px;
  margin-left: 50px;
  margin-right: 15px;
  width: 330px;
  min-width: 265px !important;
}

.rightside-medical-details-image {
  margin-left: 10px;
  margin-top: 3px;
}

.no_medical {
  text-align: center;
}

.text_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  color: #737373;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.medicaldetails {
  margin: auto;
}

button:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

.rightside-medical-details-vaccination-info {
  color: rgb(156, 156, 156);
  font-size: 14px;
}

.darkgray {
  color: rgb(129, 129, 129);
}

.rightside-medical-details-vaccination-info1 {
  color: rgb(156, 156, 156);
  font-size: 14px;
}

.rightside-blocks-with-details-card {
  display: inline-block;
}

@media only screen and (max-width: 1400px) {
  .hidecols {
    display: none;
  }
}

@media only screen and (max-width: 1200px) {
  .right-wrapper {
    margin-left: 20%;
  }
}

@media only screen and (max-width: 1024px) {
  .rightside-blocks-with-details-card {
    margin-left: 10px !important;
  }

  .right-wrapper {
    margin: auto;
  }
}

@media only screen and (max-width: 993px) {
  .rightside-blocks-4 {
    margin-left: 50px;
  }

  .widthdecrease {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}

@media only screen and (max-width: 900px) {
  .rightside-blocks-with-details-card {
    margin-left: 30px !important;
  }
}

@media only screen and (max-width: 800px) {
  .rightside-blocks-with-details-card {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}

@media only screen and (max-width: 769px) {
  .rightside-blocks-4 {
    margin-left: 0px;
  }

  .rightside-blocks-3 {
    margin-left: 50px;
  }

  .rightside-blocks-5 {
    margin-left: 50px;
  }

  .rightside-blocks-with-details-card {
    margin-left: 100px !important;
    margin-right: 0px !important;
  }

  .main-logo-img {
    width: 50px !important;
    height: 50px !important;
  }

  .med-card-title {
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 577px) {
  .rightside-blocks-with-details-card {
    margin-left: -50px !important;
    margin-right: 0px !important;
  }
}

@media only screen and (max-width: 412px) {
  .rightside-blocks-with-details-card {
    margin-left: -80px !important;
    margin-right: 0px !important;
  }
}

@media only screen and (max-width: 400px) {
  /* .rightside-medical-details-vaccination-notes {
    width: 200px !important;
  } */

  .rightside-medical-details {
    width: 250px !important;
  }
}

@media only screen and (max-width: 376px) {
  .body-medical-dashboard {
    margin-top: 50px !important;
  }

  .rightside-blocks-with-details-card {
    margin-left: -70px !important;
    margin-right: 20px !important;
  }
}

@media only screen and (max-width: 361px) {
  .body-medical-dashboard {
    margin-top: 50px !important;
  }

  .rightside-blocks-with-details-card {
    margin-left: -80px !important;
    margin-right: 20px !important;
  }
}

@media only screen and (max-width: 335px) {
  .rightside-blocks-with-details-card {
    margin-left: -90px !important;
    margin-right: 20px !important;
  }
}

@media only screen and (max-width: 320px) {
  .rightside-button-1 {
    margin-left: 0px !important;
  }

  .rightside-button-2 {
    margin-right: 10px !important;
  }

  .rightside-blocks-with-details-card {
    margin-left: -100px !important;
    margin-right: 20px !important;
  }
}

@media only screen and (max-width: 300px) {
  .rightside-blocks-with-details-card {
    margin-left: -110px !important;
    margin-right: 30px !important;
  }
}

@media only screen and (max-width: 282px) {
  .rightside-blocks-with-details-card {
    margin-left: -120px !important;
    margin-right: 45px !important;
  }
}

@media only screen and (max-width: 1320px) {
  .AddRecordDialog {
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
    display: -webkit-flex !important;
    display: flex !important;
  }

  .Add_New_Record_Dialog {
    width: 400px !important;
    padding: 0px !important;
  }
}

@media only screen and (max-width: 992px) {
  .pet-information-active {
    padding: 1rem !important;
    margin: 1rem !important;
    width: 95% !important;
  }
  .overallbody {
    padding: 60px 100px !important;
  }

  .row-sort-filter {
    width: 800px !important;
    margin-top: 30px;
  }
}

@media only screen and (max-width: 920px) {
  .menu {
    margin-left: -50px !important;
  }
}

@media only screen and (max-width: 800px) {
  .overallbody {
    padding: 60px 20px !important;
  }

  .row-sort-filter {
    width: 700px !important;
  }
}

@media only screen and (max-width: 730px) {
  .overallbody {
    padding: 60px 20px !important;
  }

  .row-sort-filter {
    width: 600px !important;
  }
}

@media only screen and (max-width: 620px) {
  .overallbody {
    padding: 60px 10px !important;
  }

  .row-sort-filter {
    width: 500px !important;
  }
}

@media only screen and (max-width: 570px) {
  .medpetparentlinks {
    margin-left: -50px !important;
  }
}

@media only screen and (max-width: 520px) {
  .forsnallsizecss {
    display: block !important;
  }

  .forbigsizecss {
    display: none;
  }
}

@media only screen and (max-width: 500px) {
  .menu {
    margin-left: 0px !important;
  }
}

@media only screen and (max-width: 480px) {
  .row-sort-filter {
    width: 300px !important;
  }
  .rightside-blocks-middle {
    font-size: 30px;
  }
}
@media only screen and (max-width: 420px) {
  .rightside-blocks > div {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
  }
  .rightside-vaccinations {
    margin-top: 0px;
  }
  .rightside-blocks-image {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 320px) {
  .row-sort-filter {
    width: 250px !important;
  }
}

@media only screen and (max-width: 290px) {
  .rightside-blocks-with-details-card {
    margin-left: -100px !important;
  }
}

/* Media Queries */

@media only screen and (max-width: 575px) {
  .row-sort-filter {
    width: 250px !important;
    text-align: left !important;
    /* background-color: aqua !important; */
  }

  .filter-div,
  .sort-div,
  .add-div {
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
  }
}

@media only screen and (max-width: 522px) {
  .no-doc-upload {
    margin-left: -15px !important;
  }

  .remaining-info-div span {
    margin-left: 5px !important;
  }
}

@media only screen and (max-width: 480px) {
  .row-sort-filter {
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-flex-direction: column !important;
            flex-direction: column !important;
    margin-right: -3rem;
  }

  .sort-div {
    margin-left: 40px !important;
  }

  .filter-div {
    margin-left: 25px !important;
  }
  .rightside-blocks {
    width: calc(100% / 3 - 20px);
  }
}

@media only screen and (max-width: 400px) and (min-width: 280px) {
  .rightside-blocks {
    margin-right: 2px !important;
  }

  .dropdown-content {
    position: relative !important;
    width: 70% !important;
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
    padding-top: 5px !important;
    margin-left: 20px !important;
    margin-right: -10px !important;
    padding-right: 0px !important;
    text-align: center !important;
  }

  .btn-ap-date {
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
  }

  .dropdown-item-1 {
    padding-right: 0px !important;
    padding: 3px;
    text-align: justify !important;
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
    font-size: 15px !important;
    margin-left: 30px !important;
  }

  .fa-arrow-down {
    margin-left: 5px !important;
  }

  .fa-arrow-up {
    margin-left: 10px !important;
  }
}

@media screen and (max-width: 768px) {
  .rightside-blocks {
    width: 40%;
    margin: 1em 0em 1em 2em;
    font-size: 12px;
  }
  /* .rightside-blocks-image {
    margin-left: 3rem;
    margin-left: 4rem;
  } */
}
@media only screen and (max-width: 400px) {
  .sort-div {
    text-align: left !important;
  }

  .dropdown-content {
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
    margin-left: -50px !important;
    margin-right: 0px !important;
  }

  .dropdown-content button {
    margin-left: 0px !important;
  }
}

/* Media Queries for Responsive Cards Start */

@media only screen and (max-width: 521px) {
  .pet-information-active {
    background-color: white !important;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px !important;
    /* width: 325px !important; */
    /* margin-left: 35px !important; */
  }

  .my-p {
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-flex-direction: row !important;
            flex-direction: row !important;
  }

  .besides {
    float: right !important;
  }

  .innermost-info1,
  .innermost-info2 {
    -webkit-flex-direction: row !important;
            flex-direction: row !important;
  }

  .reminder-doc {
    -webkit-flex-direction: column !important;
            flex-direction: column !important;
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }

  .top-row {
    margin-left: 1px !important;
    margin-top: -1px !important;
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
  }

  .med-card-title {
    position: relative !important;
    top: -65px !important;
    left: 85px !important;
    font-size: 15px !important;
  }

  .second-row {
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-flex-direction: row !important;
            flex-direction: row !important;
    /* background-color:greenyellow !important; */
    -webkit-justify-content: space-between !important;
            justify-content: space-between !important;
    margin-top: -60px !important;
    margin-left: 85px !important;
    width: 100% !important;
    color: rgba(0, 0, 0, 0.2) !important;
    font-size: 14px !important;
  }

  .float-left {
    margin-top: 0px !important;
  }

  .remaining-info-div {
    margin-top: -40px !important;
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-flex-direction: column !important;
            flex-direction: column !important;
  }

  .my-notes {
    margin-top: 10px !important;
  }

  .innermost-info2 {
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-flex-direction: row !important;
            flex-direction: row !important;
  }

  .doc-div {
    margin-top: 5px !important;
  }

  .diagnos-by,
  .diagnos-on {
    -webkit-flex-direction: column !important;
            flex-direction: column !important;
  }

  .main-logo-img {
    height: 55px !important;
    width: 55px !important;
    margin-top: 1px !important;
  }

  .pet-info-inner {
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-flex-direction: column !important;
            flex-direction: column !important;
  }
}

@media screen and (width: 48em) {
  .medicalDashboard-right-wrapper {
    padding: 0 2rem;
  }
}

@media screen and (min-width: 62.5em) {
  .sidebar-toggle-btn-wrapper {
    display: none;
  }

  .sidebar-container-hide {
    position: fixed;
    width: 15%;
    height: 100vh;
    left: 0;
    padding: 1rem;
  }
}

@media screen and (width: 64em) {
  .medicalDashboard-right-wrapper {
    margin-left: 23%;
  }
}

@media screen and (min-width: 75em) {
  .medicalDashboard-right-wrapper {
    margin-left: 22%;
  }
}

@media screen and (min-width: 78.125em) {
  .sidebar-menu-link {
    font-size: 1rem;
  }

  .sidebar-menu-ul-links {
    font-size: 0.875rem;
  }
}

/* Media query for mobile screens */
@media (max-width: 768px) {
.main-wrapper {
   margin-top: 5rem;
}
}


.cards-component-wrapper {
  margin-top: 1em;
  padding: 0.5em;
  width: 95%;
}
.notes-name-css {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-top: -2rem;
}
.cards-component-container {
  position: relative;
  border-radius: 15px;
  padding: 1em;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  grid-gap: 2em;
  gap: 2em;
  box-shadow: 0px 0px 5px 1px rgba(179, 179, 179, 0.75);
}

.cards-component-container-head {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  grid-gap: 0.5em;
  gap: 0.5em;
}
.cards-component-container-head img {
  width: 6em;
  height: 6em;
}

.document-info {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  grid-gap: 0.5;
  gap: 0.5;
  text-wrap: nowrap;
  position: absolute;
  top: 2.5rem;
  left: 0.5rem;
}

.record-name {
  text-wrap: nowrap;
}

.cards-component-container-head span label {
  font-size: 0.8125rem;
}

.cards-component-container-head span label,
.cards-component-container-head span h5 {
  margin-bottom: 0 !important;
  font-size: medium;
  font-size: initial;
  font-weight: 600;
  text-align: center;
}

.cards-component-kebab-menu {
  position: absolute;
  font-size: 1.25em;
  bottom: 2.4em;
  right: 1rem;
}
.edit-btn {
  width: 1.4em !important;
  height: 1.4em !important;
  margin-right: 4px !important;
  cursor: pointer;
}
.delete-btn {
  width: 1.4em !important;
  height: 1.4em !important;
  margin-left: 4px !important;
  cursor: pointer;
}

.document-icon {
  width: 2em !important;
  height: 2em !important;
  cursor: pointer;
}

.downloaded-img-icon {
  width: 1.75rem;
  height: 1.75rem;
}

.right-side-content {
  position: absolute;
  font-size: 15px;
  bottom: 3em;
  right: 6em;
}
.right-side-vacination-content {
  position: absolute;
  font-size: 15px;
  bottom: 1em;
  right: 5px;
}
.cards-component-main-wrapper-flex {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  grid-gap: 1rem;
  gap: 1rem;
}

.cards-component-main-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  grid-gap: 1em;
  gap: 1em;
  /* gap: 5em; */
}

.cards-component-main-wrapper .cards-component-main-content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  grid-gap: 1em;
  gap: 1em;
}
.reminder-notes {
  width: 80%;
}

.cards-component-main-content > label,
.cards-component-main-content > h5,
.cards-component-main-content > h6 {
  margin-bottom: 0px !important;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  grid-gap: 0.3em;
  gap: 0.3em;
  font-size: 1.1rem !important;
}

.cards-component-main-document-content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse;
  grid-gap: 0.4em;
  gap: 0.4em;
}

.cards-component-main-document-content > label,
.cards-component-main-document-content > span {
  margin-bottom: 0px !important;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  grid-gap: 0.3em;
  gap: 0.3em;
  position: absolute;
  bottom: -2rem;
}

.cards-component-main-content > label,
.cards-component-main-document-content > label {
  color: #61e09a;
}

.cards-component-main-document-content > span a {
  font-size: 1.25rem;
}

.cards-component-delete-dialog {
  margin-bottom: 1em;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

.cards-component-delete-dialog button {
  width: 40%;
  padding: 0.5em 1em;
  border: none;
  border-radius: 15px;
  font-weight: bold;
  letter-spacing: 1px;
}

.cards-component-delete-dialog button:nth-child(1) {
  background-color: #ffc107;
  color: white;
}

.cards-component-delete-dialog button:nth-child(1):hover {
  background-color: #daa508;
}

.cards-component-delete-dialog button:nth-child(2) {
  background-color: rgb(245, 245, 245);
  color: black;
}

.vaccination-inner-remaining-info-div,
.medication-inner-remaining-info-div,
.medical-inner-remaining-info-div,
.surgery-inner-remaining-info-div,
.measured-inner-remaining-info-div {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.card__confirm {
  -webkit-flex: 0.5 1;
          flex: 0.5 1;
  width: 100%;
  font-family: Roboto, Helvetica Neue, sans-serif;
  font-size: 14px;
  font-weight: 500;
  background-color: #28a745;
  letter-spacing: 1px;
  padding: 7px;
  border: none;
  outline: none;
  border-radius: 5px;
  color: white;
  box-shadow: 0px 2px 5px 0px #888888;
}
.card__confirm:hover {
  background-color: #1e7e34;
}
.card__cancel {
  box-shadow: 0px 2px 5px 0px #888888;
  width: 100%;
  -webkit-flex: 0.5 1;
          flex: 0.5 1;
  font-family: Roboto, Helvetica Neue, sans-serif;
  font-size: 14px;
  font-weight: 500;
  background-color: #dc3545;
  letter-spacing: 1px;
  padding: 7px;
  border: none;
  outline: none;
  border-radius: 5px;
  color: white;
}

.card__cancel:hover {
  background-color: #bd2130;
}

.inside-info {
  border-radius: 10px;
  box-shadow: 0px 0px 5px grey;
  padding: 15px 2px;
  margin-left: 10px;
  margin-right: 10px;
}

/* Media Queries */

@media only screen and (max-width: 1000px) {
  .delet-med-record {
    display: -webkit-flex !important;
    display: flex !important;
    width: 650px !important;
  }
}

@media only screen and (max-width: 540px) {
  .delete__dialog h1 {
    text-align: center !important;
  }
}

@media only screen and (max-width: 522px) {
  .docs-no-upload {
    margin-left: 25px !important;
  }

  .medcon-span {
    margin-left: 20px !important;
    background-color: chartreuse !important;
  }

  .med-con-div-1 {
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
    display: block !important;
  }
  .inside-info {
    box-shadow: none;
  }
  .inner-remaining-info-div {
    margin-top: 2rem;
  }
  .vac_row {
    margin-left: -2rem;
  }
}
@media only screen and (max-width: 360px) {
  .major__dialog_btn {
    -webkit-flex-direction: column !important;
            flex-direction: column !important;
    width: 80% !important;
  }
  .cards-component-container {
    grid-gap: 1em;
    gap: 1em;
  }
  .cards-component-container-head img {
    width: 3em;
    height: 3em;
  }
}

@media only screen and (max-width: 498px) {
  .delete__dialog h1 {
    margin-top: 2px !important;
  }
  .cards-component-container {
    grid-gap: 1em;
    gap: 1em;
  }
  .cards-component-container-head img {
    width: 3em;
    height: 3em;
  }
  .cards-component-main-wrapper-flex {
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }

  .cards-component-main-content {
    -webkit-flex-basis: calc(50% - 1em);
            flex-basis: calc(50% - 1em);
    margin: 0.3em;
  }
}

@media only screen and (max-width: 467px) {
  .delet-med-record {
    width: 320px !important;
  }
}

@media only screen and (mx-width: 430px) {
  .major__dialog_btn button {
    margin: auto, 10px !important;
  }
}

@media only screen and (max-width: 400px) {
  .delet-med-record {
    padding: 0px !important;
  }
  .delete-btn-grp {
    -webkit-flex-direction: column !important;
            flex-direction: column !important;
  }
}

.card__confirm {
  -webkit-flex: 0.5 1;
          flex: 0.5 1;
  width: 100%;
  font-family: Roboto, Helvetica Neue, sans-serif;
  font-size: 14px;
  font-weight: 500;
  background-color: #28a745;
  letter-spacing: 1px;
  padding: 7px;
  border: none;
  outline: none;
  border-radius: 5px;
  color: white;
  box-shadow: 0px 2px 5px 0px #888888;
}
.card__confirm:hover {
  background-color: #1e7e34;
}
.card__cancel {
  box-shadow: 0px 2px 5px 0px #888888;
  width: 100%;
  -webkit-flex: 0.5 1;
          flex: 0.5 1;
  font-family: Roboto, Helvetica Neue, sans-serif;
  font-size: 14px;
  font-weight: 500;
  background-color: #dc3545;
  letter-spacing: 1px;
  padding: 7px;
  border: none;
  outline: none;
  border-radius: 5px;
  color: white;
}
.card__cancel:hover {
  background-color: #bd2130;
}

/* Media Queries */

@media only screen and (max-width: 1000px) {
  .delet-med-record {
    display: -webkit-flex !important;
    display: flex !important;
    width: 650px !important;
  }
}

@media only screen and (max-width: 623px) and (min-width: 522px) {
  .top-row img {
    margin-left: -10px !important;
  }
}

@media only screen and (max-width: 522px) {
  .breaking {
    display: none !important;
  }
}

@media only screen and (max-width: 540px) {
  .delete__dialog h1 {
    text-align: center !important;
  }
}

@media only screen and (max-width: 498px) {
  .delete__dialog h1 {
    margin-top: 2px !important;
  }
}

@media only screen and (max-width: 467px) {
  .delet-med-record {
    width: 320px !important;
  }
}
@media only screen and (max-width: 730px) {
  .cards_inside {
    margin-left: 2rem;
  }
}

@media only screen and (mx-width: 430px) {
  .major__dialog_btn button {
    margin: auto, 10px !important;
  }
}

@media only screen and (max-width: 400px) {
  .delet-med-record {
    padding: 0px !important;
  }
  .delete-btn-grp {
    -webkit-flex-direction: column !important;
            flex-direction: column !important;
  }
}

@media only screen and (max-width: 340px) {
  .major__dialog_btn {
    -webkit-flex-direction: column !important;
            flex-direction: column !important;
    width: 80% !important;
  }
}

@media only screen and (max-width: 387px) {
  .my-p1 {
    display: inline !important;
  }
}

@media screen and (min-width: 43.75em) {
  /* .cards-component-wrapper {
    padding: 0.5em 7em;
  } */
}

@media screen and (min-width: 48em) {
  /* .cards-component-main-wrapper-flex {
    flex-direction: row;

    justify-content: space-between;
  } */

  .cards-component-main-wrapper {
    -webkit-flex-direction: column;
            flex-direction: column;
    /* gap: 1em; */
  }
}

.tag-number {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  font-size: 15px;
  font-weight: 500;
}

.hide {
  display: none;
}
@media screen and (min-width: 992px) {
  .mobile-view {
    display: none;
  }
}
@media screen and (max-width: 992px) {
  .laptop-view {
    display: none;
  }
}
@media screen and (max-width: 480px) {
  .cards-component-main-wrapper {
    grid-gap: 0em !important;
    gap: 0em !important;
  }
  .cards-component-main-content {
    grid-gap: 0em !important;
    gap: 0em !important;
  }
}
@media screen and (max-width: 560px) and (min-width: 380px) {
  .middle-content {
    height: 20rem;
  }
}
@media screen and (max-width: 380px) {
  .middle-content {
    height: 25rem;
  }
}
@media screen and (max-width: 320px) {
  .middle-content {
    height: 27rem;
  }
}


.addNewRecord-form {
  padding: 1em;
}

.addNewRecord-form-header {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  grid-gap: 0.5em;
  gap: 0.5em;
}

.addNewRecord-form-header > label {
  margin-bottom: 0px !important;
  font-size: 1.25rem;
  font-weight: bold;
}

.addNewRecord-form-select {
  background-color: white;
  padding: 0.5em 1em;
  border: 1px solid black;
  border-radius: 15px;
}

.addNewRecord-form-btn-wrapper {
  margin: 1em 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  grid-gap: 1em;
  gap: 1em;
}

.addNewRecord-form-btn {
  width: 100%;
  font-family: Roboto, Helvetica Neue, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 1px;
  padding: 7px;
  border: none;
  outline: none;
  border-radius: 15px;
  box-shadow: 0 2px 5px 0 #888;
}

.addNewRecord-popup-wrapper,
.addNewRecord-set-reminder {
  margin-top: 1em;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  grid-gap: 1em;
  gap: 1em;
}

.addNewRecord-popup-wrapper-div {
  display: -webkit-flex;
  display: flex;
  /* flex-direction: column; */
  grid-gap: 1em;
  gap: 1em;
}

.addNewRecord-popup-wrapper-div > label {
  margin-bottom: 0px !important;
}

.addNewRecord-popup-wrapper-div-input {
  padding: 0.5em 1em;
  border-radius: 15px;
  border: 1px solid black;
  background-color: white;
  width: 48% !important;
}

.addNewRecord-popup-wrapper-div-textarea {
  padding: 0.5em 1em;
  border-radius: 15px;
  border: 1px solid black;
  width: 48% !important;
}
.addNewRecord-popup-wrapper-div-input.addNewRecord-small-input {
  padding: 0.5em 0.75em; /* Adjust padding as needed */
  border-radius: 15px;
  border: 1px solid black;
  background-color: white;
  width: 150px; /* Adjust width as needed */
}

.addNewRecord-popup-wrapper-div-textarea.addNewRecord-small-input {
  padding: 0.5em 0.75em; /* Adjust padding as needed */
  border-radius: 15px;
  border: 1px solid black;
  width: 50% !important;
}

.addNewRecord-uploadReport-container {
  margin-top: 1em;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  grid-gap: 0.5em;
  gap: 0.5em;
}

.addNewRecord-uploadReport-container > label {
  margin-bottom: 0px !important;
  display: -webkit-flex;
  display: flex;
  grid-gap: 0.375em;
  gap: 0.375em;
}

/* @media only screen and (max-width: 540px) {
  .input-pet-medical,
  .input-pet-medical-dropdown {
    width: 160px !important;
  }
} */
.custom-breadcrumbs li::before {
  content: none;
}

.img-medicla-tab {
  width: 2.5rem;
  height: 2.5rem;
  margin-bottom: -8px;
}
.file-container {
  display: inline-block;
  max-width: 50%;
  border: 1px solid #ccc;
  border-radius: 15px;
  padding: 5px;
  margin-right: 10px;
  font-size: xx-small;
}

.file-icon {
  font-size: 24px;
  margin-right: 10px;
}

.file-name {
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

.remove-button {
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  margin-left: 5px;
  border: none;
}

.remove-icon {
  font-size: 20px;
  color: #ff0000;
}

.remove-icon:hover {
  color: #cc0000;
}

@media (max-width: 768px) {
  .addNewRecord-popup-wrapper-div {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
  }
  .addNewRecord-popup-wrapper-div-input {
    padding: 0.5em 1em;
    border-radius: 15px;
    border: 1px solid black;
    background-color: white;
    width: 100% !important;
  }

  .addNewRecord-popup-wrapper-div-textarea {
    padding: 0.5em 1em;
    border-radius: 15px;
    border: 1px solid black;
    width: 100% !important;
  }
}

@media (max-width: 360px) {
  .addNewRecord-popup-wrapper-div {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }
}

.bg-image-key {
  background-image: url("https://storage.googleapis.com/pettag/qr/thankyoukey.png");
  background-size: 100% 750px !important;
  background-repeat: no-repeat;
  height: 90vh;
}

.keyallowlocationtext {
  font-size: 22px;
  margin-top: 480px !important;
  -webkit-animation: blinker 1s linear infinite;
          animation: blinker 1s linear infinite;
  color: red;
}

@-webkit-keyframes blinker {
  50% {
    opacity: 0;
  }
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

@media only screen and (max-width: 1130px) {
  .bg-image-key {
    min-height: 700px !important;
  }
}

@media only screen and (max-width: 850px) {
  .bg-image-key {
    background-size: 100% 600px !important;
    min-height: 600px;
  }

  .keyfoundbutton {
    font-size: 16px !important;
    margin-top: 20px !important;
  }

  .keyallowlocationtext {
    margin-top: 10px !important;
  }
}

@media only screen and (max-width: 650px) {
  .bg-image-key {
    background-size: 100% 400px !important;
    height: 400px !important;
  }

  .keyallowlocationtext {
    margin-top: -210px !important;
    font-size: 20px;
  }

  .keyfoundbutton {
    font-size: 16px !important;
    height: 35px !important;
    width: 180px !important;
    padding: 1px !important;
    margin-top: 240px !important;
  }

  .keybuttonforbutton {
    margin-top: -200px !important;
  }

  .buttonforbuttonsecond {
    margin-top: -120px !important;
  }
}

@media only screen and (max-width: 480px) {
  .bg-image-key {
    background-size: 100% 300px !important;
    height: 300px !important;
  }

  .keyallowlocationtext {
    margin-top: -280px !important;
    font-size: 18px;
  }

  .keyfoundbutton {
    font-size: 12px !important;
    height: 30px !important;
    width: 200px !important;
    padding: 0px !important;
    word-wrap: break-word !important;
    white-space: normal !important;
    margin-top: 300px !important;
  }

  .keybuttonforbutton {
    margin-top: -280px !important;
  }

  .buttonforbuttonsecond {
    margin-top: -260px !important;
  }
}

@media only screen and (max-width: 350px) {
  .bg-image-key {
    background-size: 100% 200px !important;
    height: 200px !important;
  }

  .keyallowlocationtext {
    margin-top: -260px !important;
    font-size: 16px;
  }

  .keyfoundbutton {
    font-size: 12px !important;
    height: 25px !important;
    width: 150px !important;
    padding: -10px !important;
    margin-top: 250px !important;
  }

  .keybuttonforbutton {
    margin-top: -350px !important;
  }

  .buttonforbuttonsecond {
    margin-top: -330px !important;
  }
}

.bg-image {
  /* background-image: url("../../../assets/Pet\ banner\ Tag\ Scanning\ _1920x1080.jpg"); */
  background-size: 100% 95vh !important;
  background-repeat: no-repeat;
  min-height: 90vh;
}

.allowlocationtext {
  font-size: 22px;
  -webkit-animation: blinker 1s linear infinite;
          animation: blinker 1s linear infinite;
  color: red;
}
.buttonforbutton {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  margin-top: -16vh !important;
}

/* .rfbuttonleft{
    margin-right: 240px;
}
.rfbuttonright{
    margin-left: 100px;
} */

.repFound-label {
  font-size: 12px;
}
.repFound-input {
  font-size: 14px !important;
  padding: 5px !important;
  border: 1px solid lightgrey;
}
.repFound-input:focus {
  font-size: 14px !important;
  padding: 5px !important;
  border: 1px solid lightgrey !important;
}
.confirm {
  background-color: #ffc107 !important;
}
.confirm:disabled {
  background-color: #d3d3d3 !important;
}
.cancel:focus {
  padding: 5px 10px;
  background-color: white;
  border: none;
  outline: none;
  box-shadow: 0px 2px 5px 0px #888888;
}

.btn1 {
  width: 280px;
  margin-top: 10vh;
  font-size: 20px;
  word-wrap: break-word;
  text-transform: none;
}

.left {
  margin-right: 180px;
}
.right {
  margin-left: 180px;
}

@-webkit-keyframes blinker {
  50% {
    opacity: 0;
  }
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

@media only screen and (max-width: 1130px) {
  .bg-image {
    min-height: 100vh !important;
  }
  .petreportfoundbutton {
    width: 200px !important;
  }
}

@media only screen and (max-width: 850px) {
  .bg-image {
    background-size: 100% 600px !important;
    min-height: 600px;
  }

  .petreportfoundbutton {
    font-size: 16px !important;
    margin-top: 00px !important;
    width: 150px !important;
  }

  .allowlocationtext {
    margin-top: 10px !important;
  }
}

@media only screen and (max-width: 650px) {
  .bg-image {
    background-size: 100% 100vh !important;
    /* height: 400px !important; */
  }

  .allowlocationtext {
    margin-top: -120px !important;
    font-size: 20px;
  }

  .petreportfoundbutton {
    font-size: 16px !important;
    width: 100px !important;
    padding: 1px !important;
    margin-top: -250px !important;
  }

  .buttonforbutton {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin-top: 10vh !important;
  }

  .buttonforbuttonsecond {
    margin-top: -300px !important;
  }
  .btn1 {
    width: 180px !important;
    font-size: 12px;
    padding: 2px !important;
  }
  .left {
    margin-right: 0px;
  }
  .right {
    margin-left: 0px;
  }
  /* form {
    margin-top: 36vh !important;
  } */
}

@media only screen and (max-width: 550px) {
  .bg-image {
    /* background-image: url("../../../assets/thankyoubackgroundmobile.gif"); */
    background-size: 100% 100vh !important;
    background-repeat: no-repeat;
    min-height: 100vh !important;
  }
  .buttonforbutton {
    margin-top: 25vh !important;
  }
  /* form{
        margin-top: 15vh !important;
    } */
  /* .rfbuttonleft{
        text-align: left !important;
        margin-left: -60px !important;
    }
    .rfbuttonright{
        text-align: right !important;
    } */
  .btn1 {
    width: 150px !important;
  }
}

@media only screen and (max-width: 480px) {
  .allowlocationtext {
    margin-top: -140px !important;
    font-size: 18px;
  }

  .btn1 {
    width: 150px !important;
    font-size: 12px;
    padding: 2px !important;
  }

  .petreportfoundbutton {
    font-size: 14px !important;
    height: 65px !important;
    width: 100px !important;
    padding: 0px !important;
    word-wrap: break-word !important;
    white-space: normal !important;
  }

  .buttonforbuttonsecond {
    margin-top: 15vh !important;
  }
}

@media only screen and (max-width: 420px) {
  .btn1 {
    width: 100px !important;
  }
}
/* @media only screen and (max-width:368px){
    
} */

@media only screen and (max-width: 350px) {
  .bg-image {
    background-size: 100% 100vh !important;
    /* height: 80vh !important; */
  }

  .allowlocationtext {
    margin-top: -240px !important;
    font-size: 16px;
  }
  .buttonforbutton {
    margin-top: 25vh !important;
  }

  .petreportfoundbutton {
    font-size: 13px !important;
    height: 60px !important;
    width: 90px !important;
    padding: -10px !important;
  }

  .buttonforbuttonsecond {
    margin-top: 10vh !important;
  }
}

@media only screen and (max-width: 328px) {
  .rfbutton1 {
    margin-left: 10px !important;
    margin-right: 0px !important;
  }
  .rfbutton2 {
    margin-left: 0px !important;
    margin-right: -40px !important;
  }
  .btn1 {
    width: 70px !important;
  }
}

@media only screen and (max-width: 318px) {
  .rfbutton1 {
    margin-top: -350px !important;
    text-align: center !important;
    margin-left: -30px !important;
    margin-right: 10px !important;
  }
  .rfbutton2 {
    margin-top: -150px !important;
    text-align: center !important;
    margin-right: 10px !important;
  }
  .rfbuttonleft {
    text-align: center !important;
    margin-left: -65px !important;
  }
  .rfbuttonright {
    text-align: center !important;
    margin-right: -10px !important;
  }
}

@media only screen and (max-width: 300px) {
}

@media only screen and (max-width: 282px) {
}

.got-genie1 {
  background-color: #e5bd55 !important;
  padding: 10px;
  font-family: "Lato" sans-serif;
  border-radius: 10px;
  width: 160px !important;
  font-size: 15px;
  font-weight: bold;
  border: 1px solid #a78107;
  border-radius: 10px;
}

.fishes {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
}
.fish {
  position: absolute;
  top: 25px;
  left: 25px;
  z-index: 2;
}

.parent {
  position: relative;
  top: 0;
  left: 0;
}

.image1 {
  position: relative;
  top: 0;
  left: 0;
  border: 1px solid #000000;
  z-index: 1;
}

.image2 {
  position: absolute;
  top: 30px;
  left: 30px;
  border: 1px solid #000000;
  z-index: 2;
  border: none !important;
}

.image3 {
  position: absolute;
  bottom: 30px !important;
  left: 40% !important;
  border: 1px solid #000000;
  z-index: 2;
  border: none !important;
}

.small__popup {
  border: 1px solid red !important;
}

.reportlost-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  grid-gap: 1em;
  gap: 1em;
}

.reportlost-one-line {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  grid-gap: 1rem;
  gap: 1rem;
}

.reportlost-input {
  width: 50%;
}

.reportlost-container-pet-details-span,
.reportlost-container-pet-parent-details-span,
.reportlost-container-owner-reward-span {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  grid-gap: 1.2em;
  gap: 1.2em;
}

.reportlost-container-pet-details-span > h4,
.reportlost-container-pet-parent-details-span > h4,
.reportlost-container-owner-reward-span > h4 {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  grid-gap: 0.4em;
  gap: 0.4em;
  margin-top: 1em;
}

.reportlost-container-pet-details-span-input-cnt,
.reportlost-container-pet-parent-details-span-input-cnt,
.reportlost-container-owner-reward-span-select-cnt {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  grid-gap: 1em;
  gap: 1em;
}

.reportlost-container-pet-details-span-input-cnt > span,
.reportlost-container-pet-parent-details-span-input-cnt > span,
.reportlost-container-owner-reward-span-select-cnt > span {
  text-align: left;
  font-size: 1.25rem;
}

.reportlost-container-pet-details-span-input-cnt > input,
.reportlost-container-pet-parent-details-span-input-cnt > input,
.reportlost-container-owner-reward-span-select-cnt > select,
.reportlost-container-owner-reward-span-select-cnt > input {
  padding: 1rem 2rem !important;
  border-radius: 5px;
  border: 1px solid black;
  background-color: white;
}

.reportlost-container-pet-details-span-input-cnt > input,
.reportlost-container-pet-parent-details-span-input-cnt > input,
.reportlost-container-owner-reward-span-select-cnt > input {
  margin-top: 0 !important;
}

.reportlost-container-owner-reward-btns {
  /* margin: 1em 0; */
  display: -webkit-flex;
  display: flex;
  grid-gap: 1em;
  gap: 1em;
}

.date-time-loss {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.date-time-loss-input {
  padding: 1em 2em !important;
  border: 1px solid black;
  border-radius: 5px;
}

.date-time-loss-time-picker {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  grid-gap: 1em;
  gap: 1em;
}

.date-time-loss-btns {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  grid-gap: 1em;
  gap: 1em;
}

.reportlost-location {
  position: relative;
  height: 30em;
}

.reportlost-location-map-container {
  position: absolute;
  width: 100%;
  height: 16.5em;
}

.reportlost-location-input-cnt {
  display: none;
  /* position: absolute;
  top: 17em;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1em; */
}

.reportlost-location-input-cnt-lat,
.reportlost-location-input-cnt-lon {
  display: none;
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
}

.reportlost-location-input-cnt-lat > span,
.reportlost-location-input-cnt-lon > span {
  font-size: 1.1875rem;
}

.reportlost-location-btns {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  grid-gap: 1em;
  gap: 1em;
  padding: 1rem;
}

.reportlost-location-input {
  margin-top: 0px !important;
  padding: 1em 2em !important;
  border: 1px solid black;
  border-radius: 5px;
}

.reportlost-loss-details-btns {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  grid-gap: 1em;
  gap: 1em;
}

.reportlost-loss-details {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  grid-gap: 1em;
  gap: 1em;
}

.reportlost-loss-details > textarea {
  padding: 3em;
  border: 1px solid black;
  border-radius: 5px;
}

/* Yashvi key css start */
.reportLost-main-wrapper {
  width: 100%;
  /* position: absolute; */
  padding: 3.3rem 0rem;
  background-color: #c7c7c781;
  margin-top: 4rem;
}
@media screen and (max-width: 768px) {
  .reportLost-main-wrapper {
    margin-top: 0rem;
  }
}

/* .reportLost-main-form-div{

} */

.main-form {
  background-color: white;
  border-radius: 10px;
  margin-top: 3rem;
  padding: 1rem;
}

.reportLost-left-wrapper {
  position: relative;
}

.reportLost-sidebar-container {
  width: 100%;
  height: 100vh;
  z-index: 800;
  position: absolute;
  background-color: #e6e5e3;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  left: 0;
  transition: 350ms;
}

.reportLost-sidebar-container-hide {
  width: 100%;
  height: 100vh;
  z-index: 800;
  position: absolute;
  background-color: whitesmoke;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  left: -100%;
  transition: 350ms;
}

.reportLost-sidebar-toggle-btn-wrapper {
  padding: 1rem;
  width: 100%;
}

.reportLost-sidebar-toggle-btn {
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
}

.reportLost-sidebar-menu-link {
  background-color: transparent;
  border: none;
  padding: 1rem;
  text-align: left;
  font-size: 1.21875rem;
}

.reportLost-sidebar-menu-ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.reportLost-sidebar-menu-ul-hide {
  display: none;
}

.reportLost-sidebar-menu-ul-links {
  border: none;
  background-color: transparent;
  padding: 1rem 1rem 1rem 2rem;
}

.petdetails-height {
  min-height: 800px !important;
}

.background {
  min-height: 100% !important;
  height: 100%;
}
.dashboard__blacksidenav {
  width: 23rem;
  background-color: #e7e4e4;
}
.tag_inside_btn {
  height: 3rem;
  width: 7rem;
  border: none;
  outline: none;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: left;
          justify-content: left;
  -webkit-align-items: center;
          align-items: center;
  background-color: #e7e4e4;
}
.tag_inside_btn:hover {
  background-color: rgb(251 249 249);
}
.profile_name {
  padding-left: 2rem;
  color: black;
  width: 100%;
  text-decoration: none;
  list-style: none;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: left;
          flex-direction: left;
  -webkit-align-items: center;
          align-items: center;
  height: 2.3rem;
  font-size: 1.5rem;
  margin-bottom: 0.7rem;
  margin-top: -0.4rem;
}

.profile_name:hover {
  cursor: pointer;
  background-color: rgb(251 249 249);
  text-decoration: none;
}
.tag_inside_btn > span {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: left;
          justify-content: left;
  -webkit-align-items: center;
          align-items: center;
  margin-left: 3rem;
  font-size: 17px;
  letter-spacing: 1px;
}

.tag1_text {
  font-size: 17px;
  border: none;
  outline: none;
}
.my-tags {
  padding-left: 2rem;
}
.my-tags1 {
  padding-left: 2rem;
  width: 100%;
  height: 3rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: left;
          justify-content: left;
  -webkit-align-items: center;
          align-items: center;
}
.my-tags1:hover {
  cursor: pointer;
  background-color: rgb(251 249 249);
}
.user-logo {
  margin-left: 8rem;
  margin-top: 1rem;
}
.ngx-timepicker-field-example {
  height: 30px !important;
  font-size: 25px !important;
}

.lostincipet {
  text-transform: none !important;
}

.mat-button-toggle {
  width: 200px;
  border: none;
  outline: none;
  word-wrap: break-word;
}

.mat-button-toggle-group {
  border: none;
  outline: none;
}

.mat-button-toggle-group:focus .mat-button-toggle-group:checked {
  outline: none !important;
  border: none !important;
}

.mat-button-toggle:focus .mat-button-toggle:checked {
  outline: none !important;
  border: none !important;
}

.mat-button-toggle-button:focus .mat-button-toggle-button:checked {
  outline: none !important;
  border: none !important;
}

.gray-color {
  color: lightgray;
}

.bg-gray {
  background-color: lightgrey;
}

.profile-sidenav {
  letter-spacing: 1px;
  font-size: 18px;
  font-weight: 400px;
}

.p-info {
  width: 33rem;
  border-radius: 5px;
}

.lightgray-color {
  background: #e9eaed;
}

.fa-user-circle-o {
  background: #ebebeb;
  color: rgb(211, 209, 209);
  border-radius: 50%;
  border: none;
  outline: none;
  margin-top: -10px;
}

.full-name {
  font-size: 22px;
  font-weight: 350px;
  margin-top: -10px;
}

.full-name-1 {
  font-size: 17px;
  font-weight: 350px;
}

.full-name-2 {
  font-size: 17px;
  font-weight: 350px;
}

.fullname-email {
  font-size: 17px;
  font-weight: 350px;
  margin-top: -23px;
}

.full-nickname {
  margin-top: 50px;
}

.nickname {
  margin-left: 40%;
}

.nickname-1 {
  margin-left: 20%;
}

.h-100 {
  height: 100%;
}

.edit-button {
  margin-left: 35%;
  color: rgb(211, 209, 209);
  border: none;
  outline: none;
  background-color: white;
}

.mobile-update {
  width: 250px;
}

.loss-details-header {
  width: 68%;
  color: white;
  margin-left: 15%;
  border: 1px solid black;
}
.loss-details-body {
  width: 68% !important;
  margin-left: 15%;
  border: 1px solid black;
  word-wrap: break-word;
  text-overflow: clip;
}
.know-where-lost {
  font-size: 16px;
}

.fa-map-marker-alt {
  margin-top: -5px;
  font-size: 18px;
  color: gray;
}

.fa-calendar-alt {
  margin-top: -5px;
  font-size: 18px;
  color: gray;
}

.fa-clock {
  margin-top: -5px;
  font-size: 18px;
  color: gray;
}

.reward-header {
  letter-spacing: 1px;
}

.reward-body {
  font-size: 17px;
}

.input-pet-details {
  border: none;
  outline: none;
  border-bottom: 2px solid #e4e4e4;
  box-shadow: none;
  outline-width: 0;
  width: 200px;
  height: 20px;
  font-size: 14px;
}

.input-pet-details-1 {
  border: none;
  outline: none;
  border-bottom: 2px solid #e4e4e4;
  box-shadow: none;
  outline-width: 0;
  width: 440px;
  height: 20px;
  font-size: 14px;
}

.input-pet-details-2 {
  border: none;
  outline: none;
  border-bottom: 2px solid #a1a1a1;
  box-shadow: none;
  outline-width: 0;
  width: 200px;
  height: 20px;
  font-size: 14px;
  background-color: rgb(255, 234, 177);
}

.input-pet-details-dropdown {
  border: none;
  outline: none;
  border-bottom: 2px solid #e4e4e4;
  width: 200px;
  height: 20px;
  box-shadow: none;
  font-size: 14px;
  padding: 10px;
  margin-top: 0.5rem;
}
.input-pet-details-dropdown > option {
  padding: 10px;
  font-size: 0.9rem;
}

.label-petdata {
  font-size: 16px;
  color: #494949;
  font-weight: 800;
  font-family: "Lato", sans-serif;
}

.label-petdata-1 {
  font-size: 16px;
  color: #494949;
  font-weight: 800;
  font-family: "Lato", sans-serif;
}

button:focus {
  border: none;
  outline: none;
  box-shadow: none;
  background-color: white;
}

button:hover {
  border: none;
  outline: none;
  box-shadow: none;
  background-color: transparent;
}

#lost-calendar-preview {
  width: 250px;
  margin-left: 35%;
}

.text-gray {
  color: #b4b4b4;
}

.inline-display {
  display: inline-block;
}

.lost-time {
  margin: auto;
  width: 200px;
}

/* input {
  padding: 0px !important;
} */

/* @media only screen and (max-width:1310px){
    .mat-button-toggle{
        width: 150px;
    }
} */

.petparentlinks-rl {
  margin-left: -30px !important;
}

.tabs-div {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 1rem;
}
.PrivateTabIndicator-colorSecondary-3 {
  background-color: #ffc107 !important;
  height: 2.5px !important;
}
.MuiTab-root {
  font-size: 13px;
  padding: 2px;
}

.lost-div {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}

.lost-textfield {
  width: 50% !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  text-align: left !important;
}
#demo-row-radio-buttons-group-label {
  margin-left: -11rem !important;
  font-size: 12px !important;
}

/* @media only screen and (min-width: 1200px) {
  .reportLost-main-wrapper {
    height: 100%;
  }
} */

.bg-gray-height {
  height: 120vh;
}

@media only screen and (max-width: 960px) {
  /* .mat-button-toggle{
        width: 100px;
    } */
  .mat-button-toggle-group {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    margin: auto !important;
  }

  .loss-details-body {
    margin-top: 100px !important;
  }
  .dateandtime {
    margin-top: 20px !important;
  }
}

@media only screen and (max-width: 850px) {
  .mat-toggle-group-original {
    margin-top: -20px !important;
  }
  .mat-button-toggle {
    width: 150px !important;
    font-size: 14px !important;
  }
  .rightbackground {
    min-height: 1000px !important;
    height: 1000px !important;
  }
}

@media only screen and (max-width: 804px) {
  .angularlocationmap {
    height: 200px !important;
  }
}
@media screen and (min-width: 22.5em) {
  .reportlost-container-owner-reward-btns {
    margin: 1em 3em;
    /* margin: 1em 3em; */
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    grid-gap: 1em;
    gap: 1em;
  }
}

@media only screen and (max-width: 800px) {
  #lost-calendar-preview {
    margin-left: 25%;
  }
  .mat-button-toggle {
    width: 120px !important;
    font-size: 14px !important;
  }
}

@media only screen and (max-width: 747px) {
  .marginheight-rl {
    margin-top: 0px !important;
  }
}

@media only screen and (max-width: 700px) {
  .leftside-buttons-rl {
    font-size: 16px !important;
  }
}

@media only screen and (max-width: 650px) {
  #lost-calendar-preview {
    margin-left: 15%;
  }

  .reportlostheight {
    height: 100% !important;
  }

  .mat-toggle-group-original {
    margin-top: 00px !important;
  }
}

@media only screen and (max-width: 576px) {
  #lost-calendar-preview {
    margin-left: 20%;
  }
  .marginheight-rl {
    margin-top: 15px !important;
  }
  .uppercontainer {
    width: 100% !important;
    margin-right: 0px !important;
  }
}

@media only screen and (max-width: 480px) {
  /* .mat-button-toggle{
        width: 50% !important;
      } */

  .loss-details-body {
    width: 90%;
    margin: auto;
  }
  .nextback {
    height: 35px !important;
    width: 200px !important;
  }
}

@media only screen and (max-width: 450px) {
  #lost-calendar-preview {
    margin-left: 15%;
  }

  .mat-raised-button {
    width: 200px !important;
  }
  .lost-textfield {
    width: 100% !important;
  }

  .lost-div {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .gm-style-mtc > button {
    font-size: 14px !important;
    padding: 0.5rem !important;
  }
}

@media only screen and (max-width: 415px) {
  .mat-button-toggle {
    width: 160px !important;
    font-size: 14px !important;
    height: 40px !important;
  }
}

@media only screen and (max-width: 400px) {
  #lost-calendar-preview {
    margin-left: 10%;
  }

  .lost-time {
    width: 100%;
  }

  .ngx-timepicker-field-example-col {
    margin-left: -50px !important;
  }
}

@media only screen and (max-width: 380px) {
  .timerangeofloss {
    margin-top: -30px !important;
  }
  .reward_div {
    margin-left: -40px !important;
  }
  .set-time {
    font-size: 22px !important;
  }
  .lost-time-heading {
    font-size: 19px;
  }
}

@media only screen and (max-width: 350px) {
  #lost-calendar-preview {
    margin-left: 15%;
    width: 200px;
  }

  .inline-display-1 {
    display: inline-block;
  }

  .rewardcss {
    width: 200px !important;
  }

  .set-time {
    font-size: 20px !important;
  }
  .lost-time-heading {
    font-size: 17px;
  }
}

@media only screen and (max-width: 312px) {
  #lost-calendar-preview {
    margin-left: 10%;
    width: 200px;
  }

  .lost-time {
    width: 100%;
  }
  .ngx-timepicker-field-example-col {
    margin-left: -60px !important;
  }

  .reward_div {
    margin-left: -55px !important;
  }

  .set-time {
    font-size: 16px !important;
  }
  .lost-time-heading {
    font-size: 16px;
  }
}

@media only screen and (max-width: 290px) {
  #lost-calendar-preview {
    margin-left: 8%;
  }

  .mat-raised-button {
    width: 150px !important;
  }

  .ngx-timepicker-field-example-col {
    margin-left: -65px !important;
  }

  .reward_div {
    margin-left: -50px !important;
  }

  .reward_div input,
  .reward_div select {
    width: 180px !important;
  }

  .set-time {
    font-size: 13px !important;
    margin-left: -35px !important ;
  }

  .lost-time-heading {
    font-size: 15px;
  }
}

/* ----------------------------- */

.profile__leftPanel {
  display: -webkit-flex;
  display: flex;
  height: 100vh;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 20px;
  margin: 30px 1px;
  margin-left: 0;
  -webkit-flex: 0.25 1;
          flex: 0.25 1;
  background-color: #343a40;
  color: white;
}
.fa-user-circle {
  font-size: 100px;
  color: white;
  margin-bottom: 5px;
}
.profile__button {
  color: white;
  text-decoration: none;
  -webkit-align-self: baseline;
          align-self: baseline;
  margin-left: 0;
  font-size: 14px !important;
}
.profile__button:hover {
  text-decoration: none;
  color: white;
  background-color: #32383e;
}
.profile__subButtons {
  color: white;
  -webkit-align-self: baseline;
          align-self: baseline;
  margin: 0 20px;
  font-size: 14px;
  padding: 10px;
}
.profile__subButtons:hover {
  text-decoration: none;
  color: white;
  background-color: #32383e;
}

@media screen and (max-width: 550px) {
  .reportlost-one-line {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .reportlost-input {
    width: 100% !important;
  }
  .css-1nrlq1o-MuiFormControl-root {
    -webkit-align-items: baseline !important;
            align-items: baseline !important;
  }
}

@media screen and (min-width: 48em) {
  .reportlost-loss-details-btns {
    padding: 0 16%;
  }

  .reportlost-location-input-cnt {
    top: 18em;
  }

  .reportlost-location-input-cnt-lat,
  .reportlost-location-input-cnt-lon {
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
  }

  .reportlost-location-input-cnt-lat > span,
  .reportlost-location-input-cnt-lon > span {
    font-size: 1.375rem;
  }

  .reportlost-date-picker {
    width: 16.75em;
  }
}

@media screen and (min-width: 62.5em) {
  .reportLost-sidebar-toggle-btn-wrapper {
    display: none;
  }

  .reportLost-sidebar-container-hide {
    position: fixed;
    width: 15%;
    height: 100vh;
    left: 0;
    padding: 1rem;
  }

  .reportlost-location-input-cnt-lat,
  .reportlost-location-input-cnt-lon {
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }
}

@media screen and (width: 64em) {
  /* .reportLost-right-wrapper {
    margin-left: 11em;
  } */
}

@media screen and (min-width: 75em) {
  .reportLost-form-container {
    width: 38em;
    margin: 0 24rem;
  }

  .reportLost-right-wrapper {
    margin-left: 20vw;
  }
}

@media screen and (min-width: 78.125em) {
  .reportLost-sidebar-menu-link {
    font-size: 1rem;
  }

  .reportLost-sidebar-menu-ul-links {
    font-size: 0.875rem;
  }
}

@media screen and (min-width: 1200px) {
  .reportLost-main-form-div {
    width: 70%;
  }
}
@media screen and (max-width: 1200px) and (min-width: 1000px) {
  .reportLost-main-form-div {
    width: 70%;
    margin-left: 15rem;
  }
}
@media screen and (max-width: 1000px) {
  .reportLost-main-form-div {
    width: 90% !important;
    margin: auto !important;
  }
}
@media screen and (max-width: 570px) and (min-width: 490px) {
  .mapcontainer-row {
    width: 27rem !important;
  }
}
@media screen and (max-width: 490px) and (min-width: 440px) {
  .mapcontainer-row {
    width: 24rem !important;
  }
}
@media screen and (max-width: 440px) and (min-width: 380px) {
  .mapcontainer-row {
    width: 20rem !important;
  }
}
@media screen and (max-width: 380px) and (min-width: 300px) {
  .mapcontainer-row {
    width: 16rem !important;
  }
}
@media screen and (max-width: 300px) {
  .mapcontainer-row {
    width: 14rem !important;
  }
}

.pet-poster-background {
  width: 60%;
  height: 100%;
  margin: auto;
  border: 1px solid rgb(202, 167, 82);
  text-align: center;
}

a {
  text-decoration: none;
  color: black;
}

a:hover {
  color: black;
}

.lost-info-text img,
.footer-font img {
  max-width: 30px;
  max-height: 30px;
  margin-left: 2px;
  margin-right: 8px;
}

.vector {
  max-width: 30px;
  max-height: 30px;
  margin-left: 2px;
  margin-right: 8px;
}

.header-font {
  font-family: "Acme", sans-serif;
  padding: 2rem;
}

.footer-font {
  font-family: "Almarai", sans-serif;
  /* font-weight: 600; */
}

#contentToConvert {
  /* display: flex;
  flex-direction: column;
  align-items: stretch; */
  position: relative;
  min-height: 100vh;
}

.canvasWrapper {
  -webkit-flex: 1 1;
          flex: 1 1;
  padding-top: 20px;
}
.bottom-div {
  background: rgb(240, 248, 255);
  background: linear-gradient(
    180deg,
    rgba(240, 248, 255, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}
.poster-utility {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  width: 100%;
  grid-gap: 1em;
  gap: 1em;
  padding: 1em;
  z-index: 999;
}

.poster-utility-btns {
  width: 100%;
  border: none;
  border-radius: 5px;
  padding: 1em;
  background-color: #cca151;
  text-align: center;
  cursor: pointer;
}
.poster-utility-btns:hover {
  text-decoration: none;
}

.flex {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  grid-gap: 1rem;
  gap: 1rem;
}

.genieposter-nav {
  padding: 1rem;
  /* position: fixed; */
  top: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 100vw;
  z-index: 3;
  background-color: white;
}

.header-text {
  font-size: 80px;
  float: center;
  margin: auto;
  /* margin-top: 30px;
    padding-bottom: 50px; */
  margin-bottom: 30px;
  line-height: 70px;
}

.petPoster-mat-card {
  width: 80%;
  margin: auto;
  margin-top: 90px;
  /* border: 1px solid #cca151; */
  box-shadow: none !important;
}

.mat-card-header {
  margin-left: 18%;
}

.bottom-inner-div {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.pet-name {
  font-size: 34px;
  margin-top: 20px;
  font-family: "Alata", sans-serif;
}

.pet-age {
  padding-top: 1rem;
  font-family: "ABeeZee", sans-serif;
}

.mat-card-image {
  /* border: 1px solid brown; */
  border-radius: 500px;
  height: 180px;
  width: 190px;
  margin: auto;
  object-fit: cover;
}
.petposter-card-image-default {
  width: 190px;
  height: 180px;
}

.img-container {
  text-align: center;
}

.lost-info-header {
  font-size: 35px;
  line-break: normal;
  line-height: normal;
  padding-top: 10px;
  padding-right: 8px;
}

.lost-info-text {
  /* color: #cca151; */
  font-size: 21px;
  /* margin-top: -20px !important; */
  border: 0.5px solid gray;
  padding: 1.2vw;
  width: 23vw;
  border-radius: 10px;
  font-family: "ABeeZee", sans-serif;
}

.lost-info-text-address {
  /* color: #cca151; */
  font-size: 21px;
  /* margin-top: 1rem; */
  margin: 2rem;
  padding: 1.5rem;
  border: 0.5px solid gray;
  border-radius: 10px;
}

.answers-to-name {
  width: 100%;
  background-color: rgb(102, 89, 32);
  color: white;
  margin: auto;
}

.answers-to-name-header {
  font-family: "KibbyBoldFont";
  font-size: 25px;
  -webkit-transform: scale(1.6, 1.4);
          transform: scale(1.6, 1.4);
}

.answers-to-name-text-1 {
  font-family: "AbsolutDB";
  font-size: 17px;
  /* transform: scale(1.3, 1.1); */
  /* font-weight: 600; */
  border: 0.5px solid gray;
  border-radius: 10px;
}

.pabr {
  border: 0.5px solid gray;
  border-radius: 10px;
  padding: 1rem;
  background-color: white;
}

.answers-to-name-text {
  font-family: "KibbyBoldFont";
  font-size: 25px;
  -webkit-transform: scale(2.5, 2);
          transform: scale(2.5, 2);
  font-weight: 700;
}

.bottom-help-text {
  font-size: 17px;
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
}

.bottom-text {
  font-family: "BellCent NamNum BT";
  color: rgb(202, 167, 82);
  font-size: 27px;
  -webkit-transform: scale(1.2, 1.6);
          transform: scale(1.2, 1.6);
}

.bottom-text-1 {
  font-family: "BellCent NamNum BT";
  color: rgb(202, 167, 82);
  font-size: 27px;
  -webkit-transform: scale(1.6, 1.6);
          transform: scale(1.6, 1.6);
}

.reward-header {
  font-size: 25px;
  -webkit-transform: scale(1.5, 1.8);
          transform: scale(1.5, 1.8);
}

.yellow-color {
  color: rgb(202, 167, 82);
}

.button-styling {
  margin: 10px;
  font-size: 16px;
  border: none !important;
  outline: none !important;
}

.extra-btns {
  /* border: 1px solid blue; */
  /* display: flex; */
  margin-top: 5rem;
  /* padding: 0 20rem; */
}

.menu-bars {
  display: none;
}

.menu-overlay {
  display: none !important;
}

@media only screen and (max-width: 1120px) {
  .answers-to-name-header {
    font-size: 18px;
  }

  .lost-info-header-res {
    font-size: 28px;
  }
}

@media only screen and (max-width: 922px) {
  .answers-to-name-header {
    font-size: 15px;
  }

  .lost-info-header-res {
    font-size: 22px;
    line-break: normal;
  }

  .postar-res {
    padding: 5px 10px;
    font-size: 10px;
  }

  .button-styling {
    margin: 10px;
    font-size: 18px;
    padding: 5px;
  }

  .lost-info-text {
    width: 20vw;
    /* padding: 1rem; */
    height: 5rem;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
  }
}

@media only screen and (max-width: 768px) {
  .lost-info-header-res {
    font-size: 20px;
    line-break: normal;
  }

  .button-styling {
    width: 100% !important;
  }

  .flex {
    /* margin-top: 2rem; */
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    grid-gap: 0.3rem;
    gap: 0.3rem;
  }

  .img-container {
    text-align: left;
    padding: 2rem;
    padding-top: 0;
  }

  .main-img {
    text-align: center;
  }
  .reported-lost-data {
    position: static;
    margin-top: 20px;
  }
  .canvasWrapper {
    -webkit-flex: 1 1;
            flex: 1 1;
    padding-top: -20px;
  }
}
@media screen and (max-width:768px) and (min-width: 690px) {
  .lost-info-text {
    width: 36vw !important;
  }
}

/* .canvasWrapper {
  flex: 1;
  padding-top: 20px;
} */
@media only screen and (max-width: 690px) {
  .header-text {
    line-height: 4rem;
    padding: 2rem;
    font-size: 40px;
    margin-top: 5rem !important;
  }

  .lost-valuable {
    /* margin-top: 30px !important; */
    margin-bottom: 10px !important;
  }

  .answers-to-name-header {
    font-size: 12px !important;
  }

  .button-styling {
    margin: 10px;
    font-size: 18px;
    padding: 5px;
  }

  .bottom-inner-div {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
  }

  p {
    margin-bottom: 0;
  }

  .bottom-div p {
    /* padding-bottom: 1rem; */
    font-size: 16px !important;
    font-weight: 100 !important;
  }

  .lost-info-text img,
  .footer-font img,
  .vector {
    max-width: 20px;
    max-height: 20px;
    margin-left: 2px;
    margin-right: 8px;
  }

  .petPoster-mat-card {
    border: none;
    width: 100%;
    margin: 0;
    margin-bottom: 13rem !important;
  }

  .img-container {
    text-align: left;
    padding: 2rem;
    padding-top: 0;
    /* padding-top: 0; */
    /* margin-top: 4rem; */
    background-color: white;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
  }

  .colorw {
    background-color: white !important;
  }

  .p5 {
    padding-bottom: 6rem;
  }

  .main-img {
    text-align: center;
    position: absolute;
    top: -120px;
    left: 48%;
    margin-left: -10%;
    width: 60px !important;
    height: 60px;
  }

  .card-bg {
    margin-top: 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    /* padding-top: 30px; */
    /* background-color: aliceblue; */
  }

  .bottom-div {
    position: fixed;
    left: 0;
    right: 0;
    background-color: #ffffff;
    /* padding: 20px; */
    /* padding-bottom: 20px; */
    text-align: center;
    /* z-index: 1; */
  }

  /* .reported-lost-data { */
  /* position: relative; */
  /* z-index: 2; */
  /* } */
  .lost-info-text {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    width: 43vw;
    /* height: 10vh; */
  }

  .mat-card-image {
    height: 160px !important;
    width: 160px !important;
    border: 5px solid white;
  }
  .petposter-card-image-default {
    width: 160px;
    height: 160px;
  }

  .flex {
    margin-top: 0.5rem;
    margin-right: 0.5rem;
    grid-gap: 0;
    gap: 0;
  }

  .header-font {
    padding-bottom: 0;
  }

  .genieposter-nav {
    /* background-color: rgb(243, 243, 243); */
    background-color: transparent;
  }

  .row {
    background-color: rgb(243, 243, 243);
  }

  .footer-font {
    text-align: left;
  }

  .flex-between {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }

  .emergency-btn {
    display: none;
  }

  .extra-btns {
    display: none;
  }

  .menu-bars {
    display: block;
    margin-top: 1rem;
    font-size: 1.5rem;
  }

  .menu-overlay-show {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
    position: absolute;
    background-color: white;
    transition: all 0.5s ease-in-out 0s;
    z-index: 999;
    grid-gap: 1em;
    gap: 1em;
    padding: 1rem 0rem 1rem 0rem;
    list-style: none;
  }

  .menu-overlay-links {
    background-color: #dab65a;
    width: 95%;
    /* border-bottom: 0.5px solid black; */
    padding: 0.5rem 0rem 0.7rem 8rem;
    border-radius: 10px;
  }
  .menu-overlay-links-go {
    background-color: #dab65a;
    width: 95%;
    /* border-bottom: 0.5px solid black; */
    padding: 0.5rem 0rem 0.7rem 8rem;
    border-radius: 10px;
  }
  .menu-overlay-links-down {
    background-color: #dab65a;
    width: 95%;
    /* border-bottom: 0.5px solid black; */
    padding: 0.5rem 0rem 0.7rem 9rem;
    border-radius: 10px;
  }
  .menu-overlay-links-edit {
    background-color: #dab65a;
    width: 95%;
    /* border-bottom: 0.5px solid black; */
    padding: 0.5rem 0rem 0.7rem 10rem;
    border-radius: 10px;
  }
  .details-right-side {
    padding-right: 0.4rem;
  }
}

@media only screen and (max-width: 500px) {
  .answers-to-name-header {
    font-size: 10px !important;
  }

  /* .bottom-div {
    position: fixed;
    bottom: 0rem;
    padding: 5%;
    padding-bottom: 2vh;
  } */

  .main-img {
    text-align: center;
    position: absolute;
    top: -120px;
    left: 45%;
    margin-left: -10%;
    width: 60px !important;
    height: 60px;
  }
}

@media only screen and (max-width: 450px) {
  .answers-to-name-header {
    font-size: 8px !important;
  }

  /* .bottom-div {
    position: fixed;
    bottom: 0rem;
    padding: 5%;
    padding-bottom: 2vh;
  } */

  .lost-info-text {
    width: 48vw;
  }

  /* .for-margin {
    margin: 1rem 7.8rem;
  } */

  .main-img {
    text-align: center;
    position: absolute;
    top: -120px;
    left: 42%;
    margin-left: -10%;
    width: 60px !important;
    height: 60px;
  }
}

@media only screen and (max-width: 400px) {
  .petPoster-mat-card {
    width: 100%;
  }

  .header-text {
    line-height: 4.5rem;
  }

  .lost-info-header {
    font-size: 20px;
  }

  .answers-to-name {
    padding: 40px !important;
  }

  .answers-to-name-header {
    font-size: 14px !important;
  }

  .answers-to-name-text {
    font-size: 15px;
  }

  .bottom-text-1 {
    font-size: 18px;
  }

  .reward-header {
    padding: 10px 0px 0px 0px;
  }

  .bottom-text {
    padding: 0px 0px 5px 0px;
    font-size: 20px !important;
  }

  .reward-header {
    font-size: 20px !important;
  }

  /* .bottom-div {
    padding: 5%;
    padding-bottom: 1rem;
  } */
}

@media only screen and (max-width: 298px) {
  .lost-info-header-respon {
    font-size: 18px;
  }

  .button-styling {
    margin: 12px;
    font-size: 15px;
    padding: 10px;
  }

  .petPoster-mat-card {
    width: 100%;
  }

  /* .bottom-div {
    bottom: 0;
  } */
}

.lost-info-span {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  grid-gap: 1rem;
  gap: 1rem;
  width: 100% !important;
}

.lost-info-span > p {
  height: 5rem !important;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

@media screen and (max-width: 992px) {
  .lost-info-span > p {
    width: 45%;
  }
}

@media screen and (max-width: 524px) {
  .lost-info-span {
    -webkit-flex-direction: column !important;
            flex-direction: column !important;
  }
  .lost-info-span > p {
    width: 90%;
  }
  .lost-info-text-address {
    width: 90%;
  }
}

.pet-poster-background {
  width: 60%;
  height: 100%;
  margin: auto;
  border: 1px solid rgb(202, 167, 82);
  text-align: center;
}

a {
  text-decoration: none;
  color: black;
}

a:hover {
  color: black;
}

.lost-info-text img,
.footer-font img,
.lost-info-text-address img {
  max-width: 30px;
  max-height: 30px;
  margin-left: 2px;
  margin-right: 8px;
}

.vector {
  max-width: 30px;
  max-height: 30px;
  margin-left: 2px;
  margin-right: 8px;
}

.header-font {
  font-family: "Acme", sans-serif;
  padding: 2rem;
}

.footer-font {
  font-family: "Almarai", sans-serif;
  /* font-weight: 600; */
}

.bottom-div {
  background: rgb(240, 248, 255);
  background: linear-gradient(
    180deg,
    rgba(240, 248, 255, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}

/* .flex {
  display: flex;
  justify-content: space-around;
} */

.first-box {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  grid-gap: 0em;
  gap: 0em;
  margin: 1rem 0rem 0rem 0rem;
}
.genie-nav {
  padding: 1rem;
  position: fixed;
  top: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 100vw;
  z-index: 3;
}

.header-text {
  font-size: 80px;
  float: center;
  margin: auto;
  /* margin-top: 30px;
    padding-bottom: 50px; */
  margin-bottom: 30px;
  line-height: 70px;
}

.mat-card {
  width: 80%;
  margin: auto;
  margin-top: 90px;
  border: 1px solid #cca151;
}

.mat-card-header {
  margin-left: 18%;
}

.bottom-inner-div {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

.name1 {
  font-size: 34px;
  margin-top: 20px;
  font-family: "Alata", sans-serif;
}

.age1 {
  padding-top: 1rem;
  font-family: "ABeeZee", sans-serif;
}

.mat-card-image {
  /* border: 1px solid brown; */
  border-radius: 500px;
  height: 180px;
  width: 190px;
  margin: auto;
}

.img-container {
  text-align: center;
}

.lost-info-header {
  font-size: 35px;
  line-break: normal;
  line-height: normal;
  padding-top: 10px;
  padding-right: 15px;
}

.lost-info-text {
  /* color: #cca151; */
  font-size: 21px;
  /* margin-top: -20px !important; */
  border: 0.5px solid gray;
  padding: 1.2vw;
  width: 21vw;
  border-radius: 10px;
  font-family: "ABeeZee", sans-serif;
}

.lost-info-text-address {
  /* color: #cca151; */
  font-size: 21px;
  /* margin-top: 1rem; */
  margin: 2rem;
  padding: 1.5rem;
  border: 0.5px solid gray;
  border-radius: 10px;
}

.answers-to-name {
  width: 100%;
  background-color: rgb(102, 89, 32);
  color: white;
  margin: auto;
}

.answers-to-name-header {
  font-family: "KibbyBoldFont";
  font-size: 25px;
  -webkit-transform: scale(1.6, 1.4);
          transform: scale(1.6, 1.4);
}

.answers-to-name-text-1 {
  font-family: "AbsolutDB";
  font-size: 17px;
  /* transform: scale(1.3, 1.1); */
  /* font-weight: 600; */
  border: 0.5px solid gray;
  border-radius: 10px;
}

.pabr {
  border: 0.5px solid gray;
  border-radius: 10px;
  padding: 1rem;
  background-color: white;
}

.answers-to-name-text {
  font-family: "KibbyBoldFont";
  font-size: 25px;
  -webkit-transform: scale(2.5, 2);
          transform: scale(2.5, 2);
  font-weight: 700;
}

.bottom-help-text {
  font-size: 17px;
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
}

.bottom-text {
  font-family: "BellCent NamNum BT";
  color: rgb(202, 167, 82);
  font-size: 27px;
  -webkit-transform: scale(1.2, 1.6);
          transform: scale(1.2, 1.6);
}

.bottom-text-1 {
  font-family: "BellCent NamNum BT";
  color: rgb(202, 167, 82);
  font-size: 27px;
  -webkit-transform: scale(1.6, 1.6);
          transform: scale(1.6, 1.6);
}

.reward-header {
  font-size: 25px;
  -webkit-transform: scale(1.5, 1.8);
          transform: scale(1.5, 1.8);
}

.yellow-color {
  color: rgb(202, 167, 82);
}

.button-styling {
  margin: 10px;
  font-size: 16px;
  border: none !important;
  outline: none !important;
}

@media only screen and (max-width: 1120px) {
  .answers-to-name-header {
    font-size: 18px;
  }

  .lost-info-header-res {
    font-size: 28px;
  }
}

@media only screen and (max-width: 922px) {
  .answers-to-name-header {
    font-size: 15px;
  }

  .lost-info-header-res {
    font-size: 22px;
    line-break: normal;
  }

  .postar-res {
    padding: 5px 10px;
    font-size: 10px;
  }

  .button-styling {
    margin: 10px;
    font-size: 18px;
    padding: 5px;
  }

  .lost-info-text {
    width: 20vw;
  }
}

@media only screen and (max-width: 690px) {
  .lost-info-header-res {
    font-size: 20px;
    line-break: normal;
  }

  .button-styling {
    width: 100% !important;
  }

  .flex {
    margin-top: 2rem;
  }

  .name1,
  .age1 {
    margin-left: 6rem;
  }

  .img-container {
    text-align: left;
    padding: 2rem;
    padding-top: 0;
  }

  .main-img {
    text-align: center;
  }

  .header-text {
    line-height: 4rem;
    padding: 2rem;
    font-size: 40px;
    margin-top: 5rem !important;
  }

  .lost-valuable {
    /* margin-top: 30px !important; */
    margin-bottom: 0px !important;
  }

  .answers-to-name-header {
    font-size: 12px !important;
  }

  .button-styling {
    margin: 10px;
    font-size: 18px;
    padding: 5px;
  }

  .bottom-inner-div {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
  }

  p {
    margin-bottom: 0;
  }

  .bottom-div p {
    padding-bottom: 1rem;
    font-size: 16px !important;
    font-weight: 100 !important;
  }

  .lost-info-text img,
  .footer-font img,
  .vector {
    max-width: 20px;
    max-height: 20px;
    margin-left: 2px;
    margin-right: 8px;
  }

  .mat-card {
    border: none;
    width: 100%;
    margin: 0;
    margin-bottom: 13rem !important;
  }

  .img-container {
    text-align: left;
    padding: 2rem;
    padding-top: 0;
    /* padding-top: 0; */
    /* margin-top: 4rem; */
    background-color: white;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
  }

  .colorw {
    background-color: white !important;
  }

  .p5 {
    padding-bottom: 6rem;
  }

  .main-img {
    text-align: center;
    position: absolute;
    top: -120px;
    left: 48%;
    margin-left: -10%;
    width: 60px !important;
    height: 60px;
  }

  .card-bg {
    margin-top: 30px;
  }

  .bottom-div {
    position: fixed;
    bottom: 0rem;
    width: 100vw;
    padding: 5%;
    padding-bottom: 1rem;
  }

  .lost-info-text {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    width: 43vw;
    /* height: 10vh; */
  }

  .mat-card-image {
    height: 160px !important;
    width: 160px !important;
    border: 5px solid white;
  }

  .flex {
    margin-top: 0.5rem;
    margin-right: 0.5rem;
    grid-gap: 0;
    gap: 0;
  }

  .header-font {
    padding-bottom: 0;
  }

  .name1 {
    padding-top: 20px;
  }

  .genie-nav {
    /* background-color: rgb(243, 243, 243); */
    background-color: transparent;
  }

  .row {
    background-color: rgb(243, 243, 243);
  }

  .footer-font {
    text-align: left;
  }

  .flex-between {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }
}

@media only screen and (max-width: 500px) {
  .answers-to-name-header {
    font-size: 10px !important;
  }

  .bottom-div {
    position: fixed;
    bottom: 0rem;
    padding: 5%;
    padding-bottom: 2vh;
  }

  .main-img {
    text-align: center;
    position: absolute;
    top: -120px;
    left: 45%;
    margin-left: -10%;
    width: 60px !important;
    height: 60px;
  }
}

@media only screen and (max-width: 450px) {
  .answers-to-name-header {
    font-size: 8px !important;
  }

  .bottom-div {
    position: fixed;
    bottom: 0rem;
    padding: 5%;
    padding-bottom: 2vh;
  }

  .lost-info-text {
    width: 48vw;
  }

  .name1,
  .age1 {
    margin-left: 3rem;
  }

  .main-img {
    text-align: center;
    position: absolute;
    top: -120px;
    left: 42%;
    margin-left: -10%;
    width: 60px !important;
    height: 60px;
  }
}

@media only screen and (max-width: 400px) {
  .mat-card {
    width: 100%;
  }

  .header-text {
    line-height: 4.5rem;
  }

  .lost-info-header {
    font-size: 20px;
  }

  .answers-to-name {
    padding: 40px !important;
  }

  .answers-to-name-header {
    font-size: 14px !important;
  }

  .answers-to-name-text {
    font-size: 15px;
  }

  .bottom-text-1 {
    font-size: 18px;
  }

  .reward-header {
    padding: 10px 0px 0px 0px;
  }

  .bottom-text {
    padding: 0px 0px 5px 0px;
    font-size: 20px !important;
  }

  .reward-header {
    font-size: 20px !important;
  }

  .bottom-div {
    padding: 5%;
    padding-bottom: 1rem;
  }
}

@media only screen and (max-width: 298px) {
  .lost-info-header-respon {
    font-size: 18px;
  }

  .button-styling {
    margin: 12px;
    font-size: 15px;
    padding: 10px;
  }

  .mat-card {
    width: 100%;
  }

  .bottom-div {
    bottom: 0;
  }
}

.photogallery_carousel {
  text-align: center !important;
  width: 800px;
  margin-left: 200px !important;
  /* Add this */
}
.dashboard__blacksidenav {
  width: 23rem;
  background-color: #e7e4e4;
}
.tag_inside_btn {
  height: 3rem;
  width: 7rem;
  border: none;
  outline: none;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: left;
          justify-content: left;
  -webkit-align-items: center;
          align-items: center;
  background-color: #e7e4e4;
}
.tag_inside_btn:hover {
  background-color: rgb(251 249 249);
}
.profile_name {
  padding-left: 2rem;
  color: black;
  width: 100%;
  text-decoration: none;
  list-style: none;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: left;
          flex-direction: left;
  -webkit-align-items: center;
          align-items: center;
  height: 2.3rem;
  font-size: 1.5rem;
  margin-bottom: 0.7rem;
  margin-top: -0.4rem;
}

.profile_name:hover {
  cursor: pointer;
  background-color: rgb(251 249 249);
  text-decoration: none;
}
.tag_inside_btn > span {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: left;
          justify-content: left;
  -webkit-align-items: center;
          align-items: center;
  margin-left: 3rem;
  font-size: 17px;
  letter-spacing: 1px;
}

.tag1_text {
  font-size: 17px;
  border: none;
  outline: none;
}
.my-tags {
  padding-left: 2rem;
}
.my-tags1 {
  padding-left: 2rem;
  width: 100%;
  height: 3rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: left;
          justify-content: left;
  -webkit-align-items: center;
          align-items: center;
}
.my-tags1:hover {
  cursor: pointer;
  background-color: rgb(251 249 249);
}

@media only screen and (max-width: 991px) {
  .dashboard__blacksidenav {
    width: 100%;
  }
  .left_pannel_options {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
  }
  .my_tags_text {
    display: -webkit-flex;
    display: flex;
    width: 100%;
  }
  .my-tags {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
  }
  .list_links {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100%;
    margin: 0px;
  }
  .links_button {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100%;
  }
  .mytags1buttons {
    width: 100%;
  }
}

