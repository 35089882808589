.bg-image {
  /* background-image: url("../../../assets/Pet\ banner\ Tag\ Scanning\ _1920x1080.jpg"); */
  background-size: 100% 95vh !important;
  background-repeat: no-repeat;
  min-height: 90vh;
}

.allowlocationtext {
  font-size: 22px;
  animation: blinker 1s linear infinite;
  color: red;
}
.buttonforbutton {
  display: flex;
  justify-content: space-around;
  margin-top: -16vh !important;
}

/* .rfbuttonleft{
    margin-right: 240px;
}
.rfbuttonright{
    margin-left: 100px;
} */

.repFound-label {
  font-size: 12px;
}
.repFound-input {
  font-size: 14px !important;
  padding: 5px !important;
  border: 1px solid lightgrey;
}
.repFound-input:focus {
  font-size: 14px !important;
  padding: 5px !important;
  border: 1px solid lightgrey !important;
}
.confirm {
  background-color: #ffc107 !important;
}
.confirm:disabled {
  background-color: #d3d3d3 !important;
}
.cancel:focus {
  padding: 5px 10px;
  background-color: white;
  border: none;
  outline: none;
  box-shadow: 0px 2px 5px 0px #888888;
}

.btn1 {
  width: 280px;
  margin-top: 10vh;
  font-size: 20px;
  word-wrap: break-word;
  text-transform: none;
}

.left {
  margin-right: 180px;
}
.right {
  margin-left: 180px;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

@media only screen and (max-width: 1130px) {
  .bg-image {
    min-height: 100vh !important;
  }
  .petreportfoundbutton {
    width: 200px !important;
  }
}

@media only screen and (max-width: 850px) {
  .bg-image {
    background-size: 100% 600px !important;
    min-height: 600px;
  }

  .petreportfoundbutton {
    font-size: 16px !important;
    margin-top: 00px !important;
    width: 150px !important;
  }

  .allowlocationtext {
    margin-top: 10px !important;
  }
}

@media only screen and (max-width: 650px) {
  .bg-image {
    background-size: 100% 100vh !important;
    /* height: 400px !important; */
  }

  .allowlocationtext {
    margin-top: -120px !important;
    font-size: 20px;
  }

  .petreportfoundbutton {
    font-size: 16px !important;
    width: 100px !important;
    padding: 1px !important;
    margin-top: -250px !important;
  }

  .buttonforbutton {
    display: flex;
    justify-content: space-between;
    margin-top: 10vh !important;
  }

  .buttonforbuttonsecond {
    margin-top: -300px !important;
  }
  .btn1 {
    width: 180px !important;
    font-size: 12px;
    padding: 2px !important;
  }
  .left {
    margin-right: 0px;
  }
  .right {
    margin-left: 0px;
  }
  /* form {
    margin-top: 36vh !important;
  } */
}

@media only screen and (max-width: 550px) {
  .bg-image {
    /* background-image: url("../../../assets/thankyoubackgroundmobile.gif"); */
    background-size: 100% 100vh !important;
    background-repeat: no-repeat;
    min-height: 100vh !important;
  }
  .buttonforbutton {
    margin-top: 25vh !important;
  }
  /* form{
        margin-top: 15vh !important;
    } */
  /* .rfbuttonleft{
        text-align: left !important;
        margin-left: -60px !important;
    }
    .rfbuttonright{
        text-align: right !important;
    } */
  .btn1 {
    width: 150px !important;
  }
}

@media only screen and (max-width: 480px) {
  .allowlocationtext {
    margin-top: -140px !important;
    font-size: 18px;
  }

  .btn1 {
    width: 150px !important;
    font-size: 12px;
    padding: 2px !important;
  }

  .petreportfoundbutton {
    font-size: 14px !important;
    height: 65px !important;
    width: 100px !important;
    padding: 0px !important;
    word-wrap: break-word !important;
    white-space: normal !important;
  }

  .buttonforbuttonsecond {
    margin-top: 15vh !important;
  }
}

@media only screen and (max-width: 420px) {
  .btn1 {
    width: 100px !important;
  }
}
/* @media only screen and (max-width:368px){
    
} */

@media only screen and (max-width: 350px) {
  .bg-image {
    background-size: 100% 100vh !important;
    /* height: 80vh !important; */
  }

  .allowlocationtext {
    margin-top: -240px !important;
    font-size: 16px;
  }
  .buttonforbutton {
    margin-top: 25vh !important;
  }

  .petreportfoundbutton {
    font-size: 13px !important;
    height: 60px !important;
    width: 90px !important;
    padding: -10px !important;
  }

  .buttonforbuttonsecond {
    margin-top: 10vh !important;
  }
}

@media only screen and (max-width: 328px) {
  .rfbutton1 {
    margin-left: 10px !important;
    margin-right: 0px !important;
  }
  .rfbutton2 {
    margin-left: 0px !important;
    margin-right: -40px !important;
  }
  .btn1 {
    width: 70px !important;
  }
}

@media only screen and (max-width: 318px) {
  .rfbutton1 {
    margin-top: -350px !important;
    text-align: center !important;
    margin-left: -30px !important;
    margin-right: 10px !important;
  }
  .rfbutton2 {
    margin-top: -150px !important;
    text-align: center !important;
    margin-right: 10px !important;
  }
  .rfbuttonleft {
    text-align: center !important;
    margin-left: -65px !important;
  }
  .rfbuttonright {
    text-align: center !important;
    margin-right: -10px !important;
  }
}

@media only screen and (max-width: 300px) {
}

@media only screen and (max-width: 282px) {
}
