.got-genie1 {
  background-color: #e5bd55 !important;
  padding: 10px;
  font-family: "Lato" sans-serif;
  border-radius: 10px;
  width: 160px !important;
  font-size: 15px;
  font-weight: bold;
  border: 1px solid #a78107;
  border-radius: 10px;
}

.fishes {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
}
.fish {
  position: absolute;
  top: 25px;
  left: 25px;
  z-index: 2;
}

.parent {
  position: relative;
  top: 0;
  left: 0;
}

.image1 {
  position: relative;
  top: 0;
  left: 0;
  border: 1px solid #000000;
  z-index: 1;
}

.image2 {
  position: absolute;
  top: 30px;
  left: 30px;
  border: 1px solid #000000;
  z-index: 2;
  border: none !important;
}

.image3 {
  position: absolute;
  bottom: 30px !important;
  left: 40% !important;
  border: 1px solid #000000;
  z-index: 2;
  border: none !important;
}
