/* Main Container */
.editPetDetails-main-wrapper {
  width: 100%;
  background-color: hsla(0, 0%, 78%, 0.5058823529411764);
}

.editPetDetails-left-wrapper {
  position: relative;
}

.editPetDetails-sidebar-container {
  width: 100%;
  height: 100vh;
  z-index: 800;
  position: absolute;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  left: 0;
  transition: 350ms;
}

.editPetDetails-sidebar-container-hide {
  width: 100%;
  height: 100vh;
  z-index: 800;
  position: absolute;
  background-color: whitesmoke !important;
  display: flex;
  flex-direction: column;
  left: -100%;
  transition: 350ms;
}

.editPetDetails-sidebar-toggle-btn-wrapper {
  padding: 1rem;
  width: 100%;
}

.editPetDetails-sidebar-toggle-btn {
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
}

.editPetDetails-sidebar-menu-link {
  background-color: transparent;
  border: none;
  padding: 1rem;
  text-align: left;
  font-size: 1.21875rem;
}

.editPetDetails-sidebar-menu-ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.editPetDetails-sidebar-menu-ul-hide {
  display: none;
}

.editPetDetails-sidebar-menu-ul-links {
  border: none;
  background-color: transparent;
  padding: 1rem 1rem 1rem 2rem;
}

.editPetDetails-form-container-top {
  /* border: 1px solid brown; */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 1rem;
  margin-top: 0rem;
}

.editPetDetails-user-img {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid lightgray;
  border-radius: 50%;
  /* padding: 1.5rem; */
  width: 12rem;
  height: 12rem;
}

.editPetDetails-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.editPetDetails-input-user-img {
  position: absolute;
  display: none;
  top: 13em;
  left: -1.5rem;
}

.editPetDetails-edit-button-image,
.editPetDetails-delete-button-image {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* font-size: 1.3rem; */
  border: 1px solid lightgray;
  padding: 0.3rem;
  z-index: 20;
  color: black;
  cursor: pointer;
}
.editPetDetails-edit-button-image:hover,
.editPetDetails-delete-button-image:hover {
  color: #fcbc1b;
  background-color: white;
}

.editPetDetails-edit-button-image {

  top: 12.5em;
  left: -1rem;
  border-radius: 7px;
  padding: 9px 14px;
  display: flex;
  gap: 4px;
}

.editPetDetails-delete-button-image {

  top: 12.5em;
  right: -1rem;
  border-radius: 7px;
  padding: 9px 14px;
  display: flex;
  gap: 4px;
  background-color: white;
}

.editPetDetails-form {
  padding: 1rem;
}

.editPetDetails-input-wrapper {
  width: 49%;
}

.editPetDetails-input-tag {
  padding: 1rem 2rem !important;
  border: 1px solid black;
  border-radius: 5px;
}

.pet-type-span,
.pet-breed-span,
.petdata-span,
.pet-blood-grp-span,
.pet-gender-cc,
.pet-birth-span {
  margin: 1rem 0;
}

/* -webkit-appearance: none; */
/* -moz-appearance: none; */
/* appearance: none; */

.editPetDetails-select-tag {
  background-color: white;
  border: 1px solid black;
  padding: 1rem 2rem !important;
  border-radius: 5px !important;
  /* width: 100%; */
}

.editPetDetails-btn-row {
  display: flex;
  gap: 1rem;
}

.body-login {
  width: 85%;
  border-radius: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
  margin-top: 15px;
}
.image-upload {
  width: 120px;
  height: 120px;
  margin: auto !important;
}
.image {
  height: 120px;
  width: 120px;
  border-radius: 50%;
}

.overlay {
  height: 120px;
  width: 120px;
  background-color: black;
  margin-top: -120px;
  position: absolute;
  opacity: 0;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.dashboard__blacksidenav {
  width: 23rem;
  background-color: #e7e4e4;
}
.close-button {
  position: absolute !important;
  top: 0rem !important; /* Position at the top */
  right: 0.2rem !important; /* Position at the right corner */
  z-index: 10 !important; /* Ensure it stays above other elements */
  background-color: transparent !important; /* Optional for a clean look */
  border: none !important;
  cursor: pointer !important;
  color: #555 !important;
  font-size: 1.5rem !important;
  transition: color 0.3s ease !important;
}

.close-button:hover {
  color: #000;
}
.tag1_text {
  font-size: 18px;
  border: none;
  outline: none;
}
.my-tags {
  padding-left: 2rem;
}
.my-tags1 {
  padding-left: 2rem;
  width: 100%;
  height: 3rem;
  display: flex;
  justify-content: left;
  align-items: center;
}
.my-tags1:hover {
  cursor: pointer;
  background-color: rgb(251 249 249);
}

#choose-profile-imageActi {
  display: none;
}

#choose-profile-imageActi-2 {
  display: none;
}

.mat-raised-button:disabled {
  background-color: #fcbc1b;
}

.image {
  height: 120px;
  width: 120px;
  border-radius: 50%;
  text-align: center;
}

.overlay {
  height: 120px;
  width: 120px;
  background-color: black;
  margin-top: -120px;
  position: absolute;
  opacity: 0;
  border-radius: 50%;
}
.button-image {
  background-color: transparent;
  border: none;
  outline: none;
}
.fa-edit {
  margin-top: 48px;
  color: white;
}

.plus-icon {
  margin-top: 0px;
  color: #a1a1a1;
}

.image-upload:hover .overlay {
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1;
}

.pd-leftside-navigation {
  list-style: none;
  margin: auto;
  /* margin-left: -30px; */
}

#uploadPreviewActi {
  width: 120px;
  height: 120px;
  margin-top: -148px !important;
  border-radius: 50%;
  border: none;
  outline: none;
}

#uploadPreview {
  width: 120px;
  height: 120px;
  margin-top: -148px !important;
  border-radius: 50%;
  display: none;
  border: none;
  outline: none;
}

.left-side {
  border-radius: 15px 0 0 15px;
  background-color: rgb(255, 234, 177);
}



.input-pet-details {
  border: none;
  outline: none !important;
  border-bottom: 2px solid #e4e4e4;
  box-shadow: none;
  outline-width: 0;
  width: 200px;
  height: 20px;
  font-size: 14px;
}

.input-pet-details-1 {
  border: none;
  outline: none;
  border-bottom: 2px solid #e4e4e4;
  box-shadow: none;
  outline-width: 0;
  width: 440px;
  height: 20px;
  font-size: 14px;
}

.label-petdata {
  font-size: 16px;
  color: #494949;
  font-weight: 800;
  font-family: "prompt, sans-serif";
}

.label-petdata-1 {
  font-size: 16px;
  color: #494949;
  font-weight: 800;
  font-family: "prompt, sans-serif";
}

p strong {
  font-size: 18px;
}

.color-gray {
  color: #b4b4b4;
}

::ng-deep .mat-radio-label-content {
  font-weight: 550 !important;
}
.add_img {
  text-decoration: none;
  font-size: 20px;
  color: #737373;
}
.add_img:hover {
  text-decoration: none;
  color: #737373;
  cursor: pointer;
}
.edit-parent input:focus {
  border: none !important;
  outline: none !important;
  border-bottom: 2px solid #e4e4e4 !important;
  box-shadow: none !important;
  outline-width: 0 !important;
}

.edit-parent input:focus,
select:focus,
button:focus {
  outline: none !important;
}

input[readonly] {
  background-color: white;
}

.submit-login {
  width: 100%;
  height: 40px;
  border: none;
  outline: none;
  border-radius: 5px;
  font-weight: 500;
}

.file {
  border: none;
  outline: none;
  box-shadow: none;
  background-color: #fcbc1b;
  border-radius: 5px;
  width: 100px;
  height: 30px;
  font-size: 13px;
}

.gender-male {
  font-weight: none;
  font-size: 13px;
}

.gender-female {
  font-weight: none;
  font-size: 13px;
}

@media only screen and (max-width: 990px) {
  .marginheight {
    margin-top: 0px !important;
  }
  .dashboard__blacksidenav {
    height: 100% !important;
  }
  .petparentlinks {
    margin-top: -30px !important;
    margin-left: 1px !important;
  }
  .body-login {
    margin-top: 20px !important;
  }
}

@media only screen and (max-width: 850px) {
  .body-login {
    width: 100%;
  }
  .pd-leftside-navigation {
    margin-left: -35px !important;
  }
}

@media only screen and (max-width: 770px) {
  .label-petdata {
    text-align: center;
  }
  .label-petdata-1 {
    text-align: center;
  }

  .gender-female {
    margin-top: -40px !important;
  }
  /* .below-gender-move {
    margin-top: 40px;
  } */
  .pd-leftside-navigation {
    margin-left: 15px !important;
  }
  .marginheight {
    margin-top: 0px !important;
  }
}

@media only screen and (max-width: 565px) {
  .pd-leftside-navigation {
    margin-left: -50px !important;
  }
}

@media only screen and (max-width: 576px) {
  /* .below-gender-move {
    margin-top: 0px;
  } */
  .body-login {
    border-radius: 0px;
  }
  .left-side {
    border-radius: 0px 0 0 0px;
  }
  .leftside-buttons {
    margin-left: 0px !important;
  }
  .petparentlinks {
    margin-left: -50px !important;
  }
}

@media only screen and (max-width: 510px) {
  .marginheight {
    margin-top: 30px !important;
  }
}

@media only screen and (max-width: 360px) {
  .input-pet-details {
    width: 200px;
  }

  /* .input-pet-details-2 {
    width: 200px;
  } */

  /* .input-pet-details-dropdown {
    width: 200px;
  } */
  .submit-login {
    width: 200px !important;
  }
}

@media only screen and (max-width: 330px) {
  .gender-female {
    margin-top: -40px !important;
  }
  /* .below-gender-move {
    margin-top: 40px;
  } */
  .leftside-buttons {
    width: 160px !important;
  }
  .pd-leftside-navigation {
    text-align: left !important;
  }
  .petparentlinks {
    text-align: left !important;
    margin-left: -70px !important;
  }
}

@media only screen and (max-width: 300px) {
  .input-pet-details {
    width: 170px;
  }

  /* .input-pet-details-2 {
    width: 170px;
  } */

  /* .input-pet-details-dropdown {
    width: 170px;
  } */
}

/*  Media Queries Start */
@media only screen and (max-width: 1070px) and (min-width: 647px) {
  .marginheight {
    margin-top: 20px !important;
  }
}

@media screen and (min-width: 1010px) {
  .image-upload {
    margin-left: 0px !important;
  }

  /* .mynavigator button {
    padding-left: 25px !important;
    padding-right: 25px !important;
  } */
}

@media screen and (min-width: 853px) {
  .image-upload {
    margin-left: 0px !important;
  }
}

@media (max-width: 577px) {
  .background {
    padding: 45px 0px 0px 0px !important;
  }
}

@media screen and (max-width: 500px) and (min-width: 425px) {
  .image-upload {
    margin-right: 0px !important;
    margin-left: -3rem;
  }
  /* .mynavigator {
    margin-left: 0px !important;
  } */
}

@media screen and (max-width: 607px) and (min-width: 576px) {
  .myform col {
    margin-bottom: 5px !important;
  }

  /* .background {
    padding: 45px 0px 20px 20px !important;
  } */
}

@media screen and (max-width: 480px) {
  .submit-login,
  .cancel-btn {
    width: 100% !important;
    margin: 10px !important;
    height: 100% !important;
  }
}

@media screen and (min-width: 62.5em) {
  .editPetDetails-sidebar-toggle-btn-wrapper {
    display: none;
  }

  .editPetDetails-sidebar-container-hide {
    position: fixed;
    width: 15%;
    height: 100vh;
    left: 0;
    padding: 1rem;
  }
}

@media screen and (width: 64em) {
  .editPetDetails-right-wrapper {
    padding: 0 11% 0 23%;
  }
}

@media screen and (min-width: 75em) {
  /* .editPetDetails-form-container {
    width: 38em;
    margin: 0 24rem;
  } */
}

@media screen and (min-width: 78.125em) {
  .editPetDetails-sidebar-menu-link {
    font-size: 1rem;
  }

  .editPetDetails-sidebar-menu-ul-links {
    font-size: 0.875rem;
  }
}

.editPetDetails-form-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

/* Main Right Container */
.editPetDetails-right-wrapper {
  background-color: white;
  border-radius: 10px;
  width: 90%;
  margin: auto;
  padding: 15px;
}

.two-field-wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 0px;
}
@media screen and (max-width: 480px) {
  .two-field-wrapper {
    flex-direction: column;
  }
  .editPetDetails-input-wrapper {
    width: 100%;
    margin: 8px 0px;
  }
}

.MuiFormControl-root {
  width: 100% !important;
}

@media only screen and (min-width: 660px) {
  .editPetDetails-form-container {
    flex-direction: row;
  }
  .editPetDetails-form-container-top {
    margin-top: -4.5rem;
  }
}
@media screen and (min-width: 1000px) and (max-width: 1200px) {
  .editPetDetails-right-wrapper {
    width: 45rem;
    margin: 3rem 17rem;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .editPetDetails-right-wrapper {
    width: 50rem;
    margin: 3rem 22rem;
  }
}
@media screen and (min-width: 1400px) {
  .editPetDetails-right-wrapper {
    width: 50rem;
    margin: 3rem 28rem;
  }
}

/* breacrumbs css */
.mui-breadcrumb li::before {
  content: none;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .editPetDetails-main-wrapper {
    padding: 5rem 0 0rem 0;
  }
}
@media screen and (min-width: 768px) {
  .editPetDetails-main-wrapper {
    padding: 9rem 0 4rem 0;
  }
}
@media screen and (max-width: 360px) {
  .editPetDetails-main-wrapper {
    width: 100%;
  }
  .editPetDetails-right-wrapper {
    padding: 2.5rem;
  }
}

.gender-lebal {
  margin-left: 14px !important;
  font-size: 12px !important;
  margin-bottom: 0px !important;
}
.MuiFormGroup-row {
  margin-left: 14px !important;
}

.MuiFormGroup-root {
  flex-direction: row !important;
  flex-wrap: nowrap !important;
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

